import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import { db } from '../../firebase/firebase';
import {
    faTrash,
    faEdit,
    faDownload,
    faFileExcel,
    faCaretDown,
    faCaretUp,
    faPlus,
    faMagnifyingGlass,
    faSquareCaretLeft, faSquareCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils as xlsxUtils, write as writeXlsx } from 'xlsx';
import { saveAs } from 'file-saver';
import {Bar, Pie} from "react-chartjs-2";
import {
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    XCircleIcon,
    TrashIcon,
    ArrowRightCircleIcon,
    DocumentArrowDownIcon,
    PlusCircleIcon,
    MagnifyingGlassCircleIcon,
    MagnifyingGlassIcon,
    BeakerIcon, ArrowPathIcon, UserGroupIcon, ChevronDownIcon
} from "@heroicons/react/24/outline";
import {AgCharts} from "ag-charts-react";
import {useDarkMode} from "../../contexts/darkModeContext";
import ChemCard from "./ChemCard";
import {
    DialogPanel,
    Dialog,
    DialogTitle,
    Button,
    Select,
    Input
} from "@headlessui/react";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

import {
    BookOpenIcon, BriefcaseIcon, GlobeAltIcon,
    InformationCircleIcon,
    NewspaperIcon,
    PlusIcon, ShieldCheckIcon,
    UsersIcon, VideoCameraIcon,
    XMarkIcon
} from "@heroicons/react/16/solid";

export const chemOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Occurrences',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const chemOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Nutrient Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Spray Rate',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Chems = () => {
    const { currentUser } = useAuth();
    const [chems, setChems] = useState([]);
    const [chemSide, setChemSide] = useState(false);
    const [filteredChems, setFilteredChems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClassification, setSelectedClassification] = useState('');

    const { darkMode } = useDarkMode();

    const [formData, setFormData] = useState({
        name: '',
        manufacturer: '',
        classification: '',
        type: '',
        quantity: 0,
        volume: 0,
        unit: '',
        excess: 0,
        weight: 0,
        unitweight: '',
        nutrients: [],
        rate: 0,
        unitprice: 0,
    });
    const [editingChemId, setEditingChemId] = useState(null);

    const [chartOptions, setChartOptions] = useState({
        data: [],
        series: [{
            type: 'bar',
            xKey: 'name',
            yKey: 'rate',
            cornerRadius: 5,
            fill:'rgba(85, 145, 180, 0.2)',
            stroke:'rgba(85, 145, 180, 1)',
            strokeWidth:'4' }],
        height: 150,
        background: {
            visible: false,
        },
    });

    const calcRates = () => {
        return filteredChems.map(chem => ({
            name: chem.name,
            rate: chem.rate
        }));
    };

    const onUpdate = async () => {
        try {
            const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
            const snapshot = await chemsCollectionRef.get();
            const chemData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChems(chemData);
            setFilteredChems(chemData);
            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemData));
        } catch (error) {
            console.error('Error updating chem data:', error);
        }
    };

    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const storedChemsData = localStorage.getItem(`chemsData_${currentUser.uid}`);
                    if (storedChemsData) {
                        const chemsData = JSON.parse(storedChemsData);
                        setChems(chemsData);
                        setFilteredChems(chemsData);
                    } else {
                        const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                        const snapshot = await chemsCollectionRef.get();

                        if (!snapshot.empty) {
                            const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setChems(chemsData);
                            setFilteredChems(chemsData);
                            localStorage.setItem(`chemsData_${currentUser.uid}`, JSON.stringify(chemsData));
                        } else {
                            console.log('No chems found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setChemSide(userData.chemSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setChemSide(userData.chemSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchRates = async () => {
            const rates = calcRates();
            setChartOptions(prevOptions => ({
                ...prevOptions,
                data: rates,
            }));
        }


        fetchUserData();
        fetchChems();
        fetchRates()
    }, [currentUser]);





    const deleteChemFromFirestore = async (chemId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemId).delete();
            console.log('Chem item deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting chem item:', error);
        }
    };

    const fillFormWithChemData = (chem) => {
        setIsCollapsed(true);
        setFormData({
            name: chem.name,
            manufacturer: chem.manufacturer,
            classification: chem.classification,
            type: chem.type,
            quantity: chem.quantity,
            volume: chem.volume,
            unit: chem.unit,
            excess: chem.excess,
            weight: chem.weight,
            unitweight: chem.unitweight,
            nutrients: chem.nutrients,
            rate: chem.rate,
            unitprice: chem.unitprice,
        });
        setEditingChemId(chem.id);
    };

    const clearForm = () => {
        setFormData({
            name: '',
            manufacturer: '',
            classification: '',
            type: '',
            quantity: 0,
            volume: 0,
            unit: '',
            excess: 0,
            weight: 0,
            unitweight: '',
            nutrients: [],
            rate: 0,
            unitprice: 0,
        });
        setEditingChemId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');

        const parsedFormData = {
            ...formData,
            quantity: parseFloat(formData.quantity),
            volume: parseFloat(formData.volume),
            excess: parseFloat(formData.excess),
            weight: parseFloat(formData.weight),
            rate: parseFloat(formData.rate),
            unitprice: parseFloat(formData.unitprice),
        };

        try {
            if (editingChemId) {
                await collectionRef.doc(editingChemId).update(parsedFormData);
                console.log('Chem updated successfully!');
                await onUpdate();
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Chem added successfully!');
                await onUpdate();
            }

            const snapshot = await collectionRef.get();
            const updatedChemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setChems(updatedChemsData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating chem: ', error);
        }
    };


    const calculateTotalVolume = (chem) => {
        return ((chem.quantity * chem.volume) + chem.excess);
    };

    const calculateTotalContainer = (chem) => {
        return (chem.quantity + (chem.excess / chem.volume));
    };

    const calculateTotalExcess = (chem) => {
        return (chem.excess);
    };

    const calculateTotalPrice = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice);
    };

    const calculateTotalWeight = (chem) => {
        return (((chem.quantity * chem.volume) + chem.excess) * chem.weight);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleNutrientChange = (index, fieldName, e) => {
        const { value } = e.target;
        // Parse the percentage value to a number
        const parsedValue = fieldName === 'percentage' ? parseFloat(value) : value;
        setFormData(prevState => ({
            ...prevState,
            nutrients: prevState.nutrients.map((nutrient, i) =>
                i === index ? { ...nutrient, [fieldName]: parsedValue } : nutrient
            )
        }));
    };



    const handleAddNutrient = () => {
        setFormData({ ...formData, nutrients: [...formData.nutrients, { name: '', percentage: '' }] });
    };

    const handleRemoveNutrient = (index) => {
        const updatedNutrients = [...formData.nutrients];
        updatedNutrients.splice(index, 1);
        setFormData({ ...formData, nutrients: updatedNutrients });
    };




    // Function to filter chems based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = chems.filter(chem =>
            chem.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredChems(filtered);
    };

    const filterChemsByClassification = (classification) => {
        setSelectedClassification(classification);
        const filtered = chems.filter(chem => classification === '' || chem.classification === classification);
        setFilteredChems(filtered);
    };



    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Manufacturer', 'Classification', 'Type', 'Quantity', 'Volume', 'Unit', 'Excess', 'Weight', 'Unit Weight', 'Rate', 'Unit Price', 'Total Volume', 'Total Price'];
        const worksheetData = [columnHeaders, ...chems.map(chem => [chem.name, chem.manufacturer, chem.classification, chem.type, chem.quantity, chem.volume, chem.unit, chem.excess, chem.weight, chem.unitweight, chem.rate, chem.unitprice, ((chem.quantity * chem.volume) + chem.excess).toFixed(2), (((chem.quantity * chem.volume) + chem.excess) * chem.unitprice).toFixed(2)])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Chemicals');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'ChemicalInventory.xlsx');
    };

    const addOrUpdateButtonText = editingChemId ? 'Update Chem' : 'Add Chem';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    // useEffect(() => {
    //     setIsCollapsed(chemSide); // Set isCollapsed to the value of chemSide from Firebase
    // }, [chemSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const calculateNutrients = () => {
        const nutrientsMap = {};

        filteredChems.forEach(chem => {
            chem.nutrients.forEach(nutrient => {
                const { name } = nutrient;

                if (!nutrientsMap[name]) {
                    nutrientsMap[name] = 1; // Initialize count
                } else {
                    nutrientsMap[name] += 1; // Increment count
                }
            });
        });

        return Object.entries(nutrientsMap).map(([name, count]) => ({
            name,
            totalValue: count
        }));
    };





    const uniqueNutrients = calculateNutrients();

    // const rates = calcRates();
    // setChartOptions(prevOptions => ({
    //     ...prevOptions,
    //     data: rates,
    // }));


    const nutrientBarChartData = {
        labels: uniqueNutrients.map(nutrient => nutrient.name.slice(0, 1)),
        datasets: [
            {
                label: 'Occurrences',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: uniqueNutrients.map(nutrient => nutrient.totalValue.toFixed(2)),
            }
        ]
    };

    // const nutrientBarChartData2 = {
    //     labels: rates.map(chem => chem.name.slice(0,4)),
    //     datasets: [
    //         {
    //             label: 'Rate',
    //             backgroundColor: 'rgba(85, 145, 180, 0.2)',
    //             borderColor: 'rgba(85, 145, 180, 1)',
    //             borderWidth: 2,
    //             data: rates.map(chem => chem.rate.toFixed(2)),
    //         }
    //     ]
    // };

    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>


            <Dashboard/>


            <div className="w-full h-full z-20 flex">


                <div className="content pr-3 pt-3 pb-3 h-full w-full">

                    {/*Header*/}
                    <div className="flex flex-col items-center justify-center">

                        <div className=" flex items-center justify-center w-full flex-row">

                            <div className="w-1/6 flex items-center text-lg mr-2 tracking-widest">Chems <span
                                className={`text-xs ml-2`}>[{filteredChems.length}]</span>

                                <Button onClick={onUpdate}>
                                    <ArrowPathIcon className={`w-4 ml-2 text-gray-500 hover:scale-105 hover:rotate-12 hover:animate-pulse`}/>
                                </Button>

                            </div>

                            {/*Header*/}
                            <div className={`relative ${darkMode ? "bg-bgDark text-white shadow-gray-900" : "bg-white text-black"} flex items-center justify-center rounded-lg p-1 w-full mr-2`}>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                    <div className="text-sm mr-2">Total Items:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{filteredChems.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Volume:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>  {numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalVolume(chem)), 0).toFixed(2)) + " gal"}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Price:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>${numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalPrice(chem)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <Popover className="w-full h-full absolute z-50">
                                    <div className="w-full h-full">
                                        <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                            <PopoverButton className="absolute right-0 inline-flex mr-3 items-center gap-x-1 text-xs font-semibold">
                                                <ArrowDownCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/>
                                            </PopoverButton>
                                        </div>
                                    </div>

                                    <PopoverPanel
                                        transition
                                        className={`relative top-0 border ${darkMode ? 'bg-bgDark border-neutral-700' : 'bg-white'} mt-3 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                                    >
                                        <div className="mx-auto grid max-w-7xl px-6 py-6">

                                            {/*Column 1*/}

                                            <div className={`${darkMode ? "bg-bgDark shadow-gray-900" : "bg-white"} mt-2 mr-2 ml-2 p-2 flex items-center justify-center rounded-lg w-full`}>


                                                <div className="w-full h-full">
                                                    <div className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Spray Rate Comparison:</div>
                                                        {/*<Bar options={chemOptions2} data={nutrientBarChartData2} className="mr-auto ml-auto"/>*/}
                                                        <AgCharts options={chartOptions} className="mr-auto ml-auto"/>

                                                    </div>
                                                </div>


                                                <div className="w-full flex flex-col">

                                                    <div className="flex flex-row mb-2">

                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Containers:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalContainer(chem)), 0).toFixed(2))}</div>
                                                            </div>
                                                        </div>


                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} w-full mr-2 rounded-lg p-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Excess:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalExcess(chem)), 0).toFixed(2))} gal</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="flex flex-row mb-2">

                                                        <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} mr-auto ml-auto rounded-lg pl-6 pr-6 pb-1 pt-1`}>
                                                            <div className="flex flex-col items-center justify-center m-1">
                                                                <div className="text-sm mb-1">Total Weight:</div>
                                                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-2 py-0.5 border-solid border-green-600 bg-green-600/15`}>{numberWithCommas(filteredChems.reduce((total, chem) => total + parseFloat(calculateTotalWeight(chem)), 0).toFixed(2))} lbs</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>


                                                <div className="w-full max-w-screen-md h-full">
                                                    <div
                                                        className={`items-center ${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} rounded-lg mr-2 py-1 justify-center flex flex-col`}>
                                                        <div className="text-sm text-litegreen mr-2">Nutrient
                                                            Occurrences:
                                                        </div>
                                                        <Bar options={chemOptions} data={nutrientBarChartData}
                                                             className="ml-auto mr-auto"/>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </PopoverPanel>
                                </Popover>


                            </div>

                            <Button onClick={downloadAsExcel}
                                    className={`${darkMode ? "text-white" : "text-gray-400"} flex font-bold rounded-lg focus:outline-none shadow-gray-900`}>
                                <DocumentArrowDownIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            <Button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}
                                    onClick={toggleCollapse}>
                                <ArrowRightCircleIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                        </div>

                    </div>


                    {/*Search Bar Row*/}
                    <div className={`mt-2 w-full flex flex-row`}>
                        <div
                            className={`w-full flex flex-row items-center ${darkMode ? "bg-bgDark" : "bg-white shadow-sm"} rounded-lg px-2 mr-2`}>
                            <MagnifyingGlassIcon className={`w-4 mr-2`}/>
                            <Input
                                type="text"
                                placeholder="Search chems by name"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                className={`rounded-lg block w-full bg-transparent`}
                            />
                        </div>

                        <div className="w-1/4 flex flex-row items-center">
                            <Select
                                value={selectedClassification}
                                onChange={(e) => filterChemsByClassification(e.target.value)}
                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgDark dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                            >
                                <option value="">All Classifications</option>

                                <option value="Fungicide">Fungicide</option>
                                <option value="Herbicide">Herbicide</option>
                                <option value="Pesticide">Pesticide</option>
                                <option value="Growth Reg">Growth Reg</option>
                                <option value="Fertilizer">Fertilizer</option>
                                <option value="Wetting Agent">Wetting Agent</option>
                                <option value="Micronutrient">Micronutrient</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Soil Amends">Soil Amends</option>
                                <option value="2nd Nutrient">2nd Nutrient</option>
                                {/* Add more options as needed */}
                            </Select>

                        </div>

                    </div>


                    {/*Body*/}
                    <div
                        className={`relative z-40 h-full mt-2 w-full rounded-lg overflow-y-auto max-h-[calc(100vh-11rem)] scrollbar-none`}>

                        <div>

                            {filteredChems.length === 0 ? (
                                // Display custom dialog if sprays array is empty


                                <div className="absolute flex w-full h-full justify-center items-center">
                                    <div
                                        className={`flex animate-fadeInFast flex-col items-center border-2 border-dashed border-neutral-500 ${darkMode ? "text-white" : "text-black"} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>

                                    <BeakerIcon className={`w-12 stroke-1`}/>
                                        <h3 className="mt-2 text-sm font-semibold">No Chems</h3>
                                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new Chem.</p>
                                        <div className="mt-6">
                                            <Button
                                                type="button"
                                                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={toggleCollapse}
                                            >
                                                <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                                Add Chem
                                            </Button>
                                        </div>

                                    </div>
                                </div>


                                // <div className=" flex flex-col border border-dashed w-full h-full justify-center items-center">
                                //     <svg
                                //         fill="none"
                                //         stroke="currentColor"
                                //         viewBox="0 0 24 24"
                                //         aria-hidden="true"
                                //         className="mx-auto size-12 text-gray-400"
                                //     >
                                //         <path
                                //             d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                //             strokeWidth={2}
                                //             vectorEffect="non-scaling-stroke"
                                //             strokeLinecap="round"
                                //             strokeLinejoin="round"
                                //         />
                                //     </svg>
                                //     <h3 className="mt-2 text-sm font-semibold text-gray-900">No projects</h3>
                                //     <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
                                //     <div className="mt-6">
                                //         <button
                                //             type="button"
                                //             className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                //         >
                                //             <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                                //             New Project
                                //         </button>
                                //     </div>
                                // </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                filteredChems.map((chem, index) => {
                                    return (
                                        <ChemCard
                                            key={index}
                                            chem={chem}
                                            onDelete={() => deleteChemFromFirestore(chem.id)}
                                            onEdit={fillFormWithChemData}
                                        />
                                    );
                                })
                            )}

                        </div>


                    </div>


                </div>


            </div>


            <Dialog open={isCollapsed} onClose={setIsCollapsed} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 mt-[4.75rem] pb-3 pr-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex h-full flex-col overflow-y-scroll ${darkMode ? 'bg-bgDark' : 'bg-white'} rounded-lg shadow-2xl`}>


                                    {/*Drawer Header*/}
                                    <div className={`${darkMode ? 'bg-bgcard/30 border-neutral-700' : 'bg-bgcolorLight'} border-b-4 border-dashed px-4 py-6 sm:px-6`}>
                                        <div className="flex items-center justify-between">
                                            <Button type="button" onClick={clearForm} className="font-bold py-1 px-1 rounded-lg mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                                                <XCircleIcon className="w-6 hover:text-red-700 flex-none" aria-hidden="true"/>
                                            </Button>

                                            <DialogTitle className="text-base font-semibold tracking-widest">Add Chemical</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setIsCollapsed(false)}
                                                    className="relative rounded-md hover:text-green-500"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <p className="text-xs text-green-500">This is the form for adding new chemicals to your database. Once filled out you will see it appear to your left.</p>
                                        </div>
                                    </div>


                                    {/*Drawer Content*/}
                                    <div className="relative flex-1 px-4 sm:px-6">

                                        <form onSubmit={handleSubmit} className="mt-2 py-3 rounded-lg overflow-y-auto max-h-[calc(100vh-13.5rem)] scrollbar-none animate-fadeIn">

                                            <div className="justify-center items-center text-center tracking-widest text-xs">IDENTIFIERS</div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-sm mr-3" htmlFor="name">
                                                    Name:
                                                </label>

                                                <Input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-sm mr-3" htmlFor="manufacturer">
                                                    Manufacturer:
                                                </label>

                                                <Input
                                                    type="text"
                                                    id="manufacturer"
                                                    name="manufacturer"
                                                    value={formData.manufacturer}
                                                    onChange={handleChange}
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div className="mt-4 justify-center items-center text-center tracking-widest text-xs">CLASSIFIERS</div>


                                            <div className="flex items-center justify-start mt-2 mb-2">
                                                <label className="block text-sm mr-3" htmlFor="classification">
                                                    Classification:
                                                </label>

                                                <Select
                                                    id="classification"
                                                    name="classification"
                                                    value={formData.classification}
                                                    onChange={handleChange}
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Fungicide">Fungicide</option>
                                                    <option value="Herbicide">Herbicide</option>
                                                    <option value="Pesticide">Pesticide</option>
                                                    <option value="Growth Reg">Growth Reg</option>
                                                    <option value="Fertilizer">Fertilizer</option>
                                                    <option value="Wetting Agent">Wetting Agent</option>
                                                    <option value="Micronutrient">Micronutrient</option>
                                                    <option value="Biostimulants">Biostimulants</option>
                                                    <option value="Soil Amends">Soil Amends</option>
                                                    <option value="2nd Nutrient">2nd Nutrient</option>

                                                </Select>
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-sm mr-3" htmlFor="type">
                                                    Type:
                                                </label>

                                                <Select
                                                    id="type"
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={handleChange}
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Liquid">Liquid</option>
                                                    <option value="Granular">Granular</option>
                                                    <option value="Foliar">Foliar</option>
                                                    <option value="Soluable">Soluable</option>

                                                </Select>
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="mt-4 justify-center items-center text-center tracking-widest text-xs">MEASUREMENTS
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-sm mr-3" htmlFor="quantity">
                                                    Quantity:
                                                </label>

                                                <Input
                                                    type="number"
                                                    id="quantity"
                                                    name="quantity"
                                                    value={formData.quantity}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div className="flex mb-2">
                                                <div className="w-full mr-5">
                                                    <label className="block text-sm mb-2" htmlFor="volume">
                                                        Volume:
                                                    </label>

                                                    <Input
                                                        type="number"
                                                        id="volume"
                                                        name="volume"
                                                        value={formData.volume}
                                                        onChange={handleChange}
                                                        className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                    />
                                                </div>

                                                <div className="w-full">
                                                    <label className="block text-sm mb-2" htmlFor="unit">
                                                        Unit:
                                                    </label>

                                                    <div className="flex flex-row mb-2">
                                                        <Select
                                                            id="unit"
                                                            name="unit"
                                                            value={formData.unit}
                                                            onChange={handleChange}
                                                            className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        >
                                                            <option value="">Select...</option>

                                                            <option value="gal">gal</option>
                                                            <option value="oz">oz</option>
                                                            <option value="lbs">lbs</option>
                                                            <option value="ml">ml</option>
                                                            <option value="L">L</option>

                                                        </Select>

                                                    </div>

                                                </div>
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-sm mr-3" htmlFor="excess">
                                                    Excess:
                                                </label>

                                                <Input
                                                    type="number"
                                                    id="excess"
                                                    name="excess"
                                                    value={formData.excess}
                                                    onChange={handleChange}
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div className="flex">
                                                <div className="w-full mr-5">
                                                    <label className="block text-sm mb-2" htmlFor="weight">
                                                        Weight per gal:
                                                    </label>

                                                    <Input
                                                        type="number"
                                                        id="weight"
                                                        name="weight"
                                                        value={formData.weight}
                                                        onChange={handleChange}
                                                        className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                    />
                                                </div>

                                                <div className="w-full">
                                                    <label className="block text-white text-sm mb-2"
                                                           htmlFor="unitweight">
                                                        Unit:
                                                    </label>

                                                    <Select
                                                        id="unitweight"
                                                        name="unitweight"
                                                        value={formData.unitweight}
                                                        onChange={handleChange}
                                                        className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                    >
                                                        <option value="">Select...</option>

                                                        <option value="lbs">lbs</option>
                                                        <option value="oz">oz</option>
                                                        <option value="kg">kg</option>
                                                        <option value="g">g</option>

                                                    </Select>
                                                </div>
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to calculate your volume
                                            </div>


                                            <div className="flex flex-row items-center justify-between">
                                                <div className="w-1/3"></div>
                                                <div className="w-1/3 text-center text-xs tracking-widest">NUTRIENTS</div>
                                                <div className="w-1/3 flex justify-end">
                                                    <Button type="button" onClick={handleAddNutrient} className="rounded-full text-center">
                                                        <PlusCircleIcon className={`w-6 hover:text-green-500`}/>
                                                    </Button>
                                                </div>
                                            </div>

                                            {formData.nutrients.map((nutrient, index) => (
                                                <div key={index} className="flex items-center mt-2 mb-2 justify-start">

                                                    <Select
                                                        id="unitweight"
                                                        name="unitweight"
                                                        value={nutrient.name}
                                                        onChange={(e) => handleNutrientChange(index, 'name', e)} // Pass the field name explicitly
                                                        className={`rounded-lg block w-3/4 mr-2 p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                    >
                                                        <option value="">Select...</option>

                                                        <option value="Potassium PolyPhosphite">Potassium PolyPhosphite</option>
                                                        <option value="Nitrogen[n]">Nitrogen[n]</option>
                                                        <option value="Phosphate[p2o5]">Phosphate[p2o5]</option>
                                                        <option value="Potassium[k]">Potassium[k]</option>
                                                        <option value="Calcium[ca]">Calcium[ca]</option>
                                                        <option value="Magnesium[Mg]">Magnesium[Mg]</option>
                                                        <option value="Sulfur[s]">Sulfur[s]</option>
                                                        <option value="Boron[B]">Boron[B]</option>
                                                        <option value="Cobalt[Co]">Cobalt[Co]</option>
                                                        <option value="Copper[Cu]">Copper[Cu]</option>
                                                        <option value="Iron[Fe]">Iron[Fe]</option>
                                                        <option value="Manganese[Mn]">Manganese[Mn]</option>
                                                        <option value="Molybdate[Mo]">Molybdate[Mo]</option>
                                                        <option value="Zinc[Zn]">Zinc[Zn]</option>
                                                        <option value="Humic Acid">Humic Acid</option>
                                                        <option value="Carbohydrates">Carbohydrates</option>
                                                        <option value="Amino Acid Substrates">Amino Acid Substrates</option>
                                                        <option value="Soluble Potash">Soluble Potash</option>


                                                    </Select>
                                                    <Input
                                                        type="number"
                                                        value={nutrient.percentage}
                                                        onChange={(e) => handleNutrientChange(index, 'percentage', e)} // Pass the field name explicitly
                                                        placeholder="%"
                                                        className={`rounded-lg block w-1/4 mr-2 p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                    />
                                                    <Button type="button" onClick={() => handleRemoveNutrient(index)}>
                                                        <XCircleIcon className={`w-6 text-red-500 hover:text-opacity-50`}/>
                                                    </Button>
                                                </div>
                                            ))}

                                            <div
                                                className="mt-4 justify-center items-center text-center tracking-widest text-xs">APPLICATION
                                                RATES
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-sm mr-3" htmlFor="rate">
                                                    Rate:
                                                </label>

                                                <Input
                                                    type="number"
                                                    id="rate"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to Calculate your spray formulas
                                            </div>


                                            <div
                                                className="mt-4 justify-center items-center text-center tracking-widest text-xs">PRICE
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-sm mr-3" htmlFor="unitprice">
                                                    UnitPrice:
                                                </label>

                                                <input
                                                    type="number"
                                                    id="unitprice"
                                                    name="unitprice"
                                                    value={formData.unitprice}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgcard dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>
                                                This is the data that the system uses to calculate your cost analysis
                                            </div>


                                            {/* Add more input fields as needed */}
                                            <div className="flex w-full justify-center mt-4">
                                                {/* Add or Update Chem button */}
                                                <button type="submit"
                                                        className="w-full bg-darkgreen hover:bg-litegreen text-white font-bold py-1 px-4 rounded-lg focus:outline-none focus:shadow-outline">
                                                    {addOrUpdateButtonText}
                                                </button>
                                            </div>


                                        </form>

                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>


        </div>
    );
};

export default Chems;
