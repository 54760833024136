import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';
import imageSrc from '../../FullLogo.png';
import imageSrcDark from '../../FullLogoDark.png';
import {
    SunIcon,
    MoonIcon,
    ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";
import {Button} from "@headlessui/react";
import {db} from "../../firebase/firebase";
import CyberBackground from "../CyberBackground";

const Header = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const { currentUser } = useAuth();
    const { darkMode, toggleDarkMode } = useDarkMode(); // Get dark mode state and toggler from context

    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const [profileImage, setProfileImage] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setProfileImage(userData.profileImage);
                        setName(userData.fname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setProfileImage(userData.profileImage);
                            setName(userData.fname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();

    }, [currentUser]);


    return (
        <div className={`w-full p-3 h-16 fixed top-0 left-0 ${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"}`}>

            <CyberBackground/>

            <div className={`flex z-20 flex-row justify-between items-center w-full rounded-md ${darkMode ? "bg-bgDark" : "bg-white drop-shadow shadow-gray-900"}`}>

                {/*Logo*/}
                <Link className='w-full text-sm text-white underline mx-5 p-1' to={'/landing'}>
                    {darkMode ? (
                        <img src={imageSrc} alt="" className="h-12 hover:animate-pulse"/>
                    ) : (
                        <img src={imageSrcDark} alt="" className="h-12 hover:animate-pulse"/>
                    )}
                </Link>

                {/*Middle*/}
                <div className="flex w-full justify-center items-center">
                    <div className={`${darkMode ? "bg-bgcard   " : "bg-bgcolorLight border border-neutral-300 drop-shadow"} mr-auto text-sm rounded-lg px-2 py-0.5 shadow-gray-900`}>
                        {time}
                    </div>


                    {/*DarkMode Toggle*/}
                    <div className="">
                        <input
                            type="checkbox"
                            id="spray"
                            name="spray"
                            checked={darkMode}
                            onChange={toggleDarkMode} // Call the toggle function from context
                            className="sr-only"
                        />
                        <label htmlFor="spray" className="cursor-pointer">
                            <div
                                className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-bgpage' : 'bg-bgcolorLight drop-shadow shadow-gray-900'}`}>
                                <div
                                    className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-white translate-x-full' : 'bg-white'}`}>
                                    {darkMode ? (
                                        <MoonIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <SunIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </div>
                        </label>
                    </div>


                    <div className={`${darkMode ? "bg-bgcard   " : "bg-bgcolorLight border border-neutral-300 drop-shadow"} ml-auto text-sm rounded-md px-2 py-0.5 shadow-gray-900`}>
                        {date}
                    </div>
                </div>

                {/*Profile*/}
                <div className="w-full flex justify-end items-center">

                    <a
                        href="/Account"
                    >
                        <img
                            alt=""
                            src={profileImage}
                            className={`size-9 rounded-full border border-gray-500`}
                        />
                    </a>

                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-xs">
                        Welcome {name}
                      </span>
                    </span>


                    <nav>
                        {userLoggedIn ? (
                            <Button
                                onClick={() => {
                                    doSignOut().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                className={`${darkMode ? "  " : "drop-shadow"} text-sm text-white h-8 rounded-md hover:animate-pulse p-1 flex items-center justify-center shadow-gray-900 ml-3 mr-3`}>
                                <ArrowRightOnRectangleIcon className={`w-6 text-red-500`}/>
                            </Button>
                        ) : (
                            <>
                                <Link className='text-sm text-white underline mx-2' to={'/login'}>Login</Link>
                                <Link className='text-sm text-white underline mx-2' to={'/register'}>Register New
                                    Account</Link>
                            </>
                        )}
                    </nav>
                </div>

            </div>
        </div>
    );
};

export default Header;
