import { useEffect, useState } from 'react';

export function useDrawingManager() {
    const [drawingManager, setDrawingManager] = useState(null);

    useEffect(() => {
        if (!window.google) return; // Check if Google Maps API is loaded

        const drawing = window.google.maps.drawing;

        if (!drawing) return; // Check if Drawing library is loaded

        const newDrawingManager = new drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON,
                    window.google.maps.drawing.OverlayType.POLYLINE,
                    window.google.maps.drawing.OverlayType.RECTANGLE,
                ],
            },
            polygonOptions: {
                editable: true,
                draggable: true,
            },
            polylineOptions: {
                editable: true,
                draggable: true,
            },
            rectangleOptions: {
                editable: true,
                draggable: true,
            },
        });

        setDrawingManager(newDrawingManager);

        return () => {
            if (newDrawingManager) {
                newDrawingManager.setMap(null);
            }
        };
    }, []);

    return drawingManager;
}
