import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth';
import { db } from '../../../firebase/firebase';
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";
import course from "../../../photos/course.jpg";
import logo from "../../../FullLogo.png";
import {Button} from "@headlessui/react";


const Register = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const { userLoggedIn } = useAuth();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            setIsRegistering(true);
            try {
                // Create user with email and password
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);

                // Access the user object from the userCredential
                const user = userCredential.user;


                // Add user data to Firestore collection
                await db.collection('Users').doc(user.uid).set({
                    fname: fname,
                    lname: lname,
                    company: company,
                    title: title,
                    email: email
                });

                // Registration successful, user is now logged in
                setIsRegistering(false);
            } catch (error) {
                console.error('Error registering user:', error.message);
                setIsRegistering(false);
            }
        }
    };

    return (
        <div className={`w-full h-screen bg-gray-900/50 overflow-y-hidden`}>
            {userLoggedIn && <Navigate to={'/Home'} replace={true}/>}

            <Link to={'/landing'}
                  className="animate-slideInLeft absolute flex top-4 hover:text-green-600 text-white left-4 text-sm p-2 rounded-full transition duration-200">
                <ArrowLongLeftIcon className={`w-4 mr-2`}/>
                Return to Home
            </Link>


            <img
                alt=""
                src={course}
                className="absolute inset-0 object-cover -z-10 size-full"
            />

            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-green-600 to-green-400 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>


            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="animate-slideInTop sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        alt="Your Company"
                        src={logo}
                        className="mx-auto h-14 w-auto"
                    />
                    <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-white">
                        Create An Account!
                    </h2>
                </div>

                <div className="mt-6 animate-slideInBottom sm:mx-auto sm:w-full sm:max-w-[480px]">

                    <div className="bg-white/30 backdrop-blur-sm px-6 py-6 shadow sm:rounded-lg sm:px-12">


                        <form onSubmit={onSubmit} className="space-y-4">
                            <div className="flex">
                                <div className="flex-grow mr-1">
                                    <input
                                        type="fname"
                                        autoComplete='fname'
                                        required
                                        placeholder="First Name:"
                                        value={fname} onChange={(e) => {
                                        setFname(e.target.value)
                                    }}
                                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                    />
                                </div>
                                <div className="flex-grow ml-1">
                                    <input
                                        type="lname"
                                        autoComplete='lname'
                                        required
                                        placeholder="Last Name:"
                                        value={lname} onChange={(e) => {
                                        setLname(e.target.value)
                                    }}
                                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                    />
                                </div>
                            </div>

                            <div>
                                <input
                                    type="company"
                                    autoComplete='company'
                                    required
                                    placeholder="Company:"
                                    value={company} onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                />
                            </div>

                            <div>
                                <input
                                    type="title"
                                    autoComplete='title'
                                    required
                                    placeholder="Title:"
                                    value={title} onChange={(e) => {
                                    setTitle(e.target.value)
                                }}
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                />
                            </div>

                            <div>
                                <input
                                    type="email"
                                    autoComplete='email'
                                    required
                                    placeholder="Email:"
                                    value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                />
                            </div>

                            <div>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='new-password'
                                    required
                                    placeholder="Password:"
                                    value={password} onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                />
                            </div>

                            <div>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='off'
                                    required
                                    placeholder="Password Confirm:"
                                    value={confirmPassword} onChange={(e) => {
                                    setconfirmPassword(e.target.value)
                                }}
                                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                />
                            </div>

                            <Button
                                type="submit"
                                disabled={isRegistering}
                                className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-darkgreen hover:bg-litegreen hover:shadow-xl transition duration-300'}`}
                            >
                                {isRegistering ? 'Signing Up...' : 'Sign Up'}
                            </Button>
                        </form>


                    </div>

                    <p className="mt-10 text-center text-sm/6 text-gray-500">
                        Already a member?{' '}
                        <a href="/login" className="font-semibold text-green-600 hover:text-opacity-50">
                            Sign In Now!
                        </a>
                    </p>
                </div>
            </div>


            {/*<main className="w-full h-screen flex self-center place-content-center place-items-center">*/}
            {/*    <div className="w-96 text-gray-600 space-y-5 p-4 shadow-2xl  rounded-xl">*/}
            {/*        <div className="text-center mb-6">*/}
            {/*            <div className="mt-2">*/}
            {/*                <h3 className="text-white text-xl font-semibold sm:text-2xl">Sign Up</h3>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</main>*/}


        </div>
    );
}

export default Register;
