import React, {useEffect, useState} from 'react'
import { Navigate, Link } from 'react-router-dom'
import { doSignInWithEmailAndPassword, doPasswordReset } from '../../../firebase/auth' // Import password reset function
import { useAuth } from '../../../contexts/authContext'
import {db} from "../../../firebase/firebase";
import {Button} from "@headlessui/react";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";
import logo from "../../../FullLogo.png"
import course from "../../../photos/course.jpg";

const Login = () => {
    const { userLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [defaultPage, setDefaultPage] = useState('')

    const [isSigningIn, setIsSigningIn] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [showResetPopup, setShowResetPopup] = useState(false)
    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            await doSignInWithEmailAndPassword(email, password)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (userLoggedIn) {
                try {
                    const userDoc = await db.collection('Users').doc(userLoggedIn.uid).get();
                    if (userDoc.exists) {
                        const userData = userDoc.data();
                        const userDefaultPage = userData.defaultPage || '';
                        console.log('User defaultPage:', userDefaultPage);
                        setDefaultPage(userDefaultPage);
                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [userLoggedIn]);

    console.log('Default page:', defaultPage);

    const handleResetSubmit = async (e) => {
        e.preventDefault()
        try {
            await doPasswordReset(resetEmail)
            alert('Password reset email sent! Please check your inbox.')
            setShowResetPopup(false)
        } catch (error) {
            console.error('Error resetting password:', error.message)
        }
    }

    return (
        <div className={`w-full h-screen bg-gray-900/50 overflow-y-hidden`}>

            {userLoggedIn && (
                defaultPage === 'Calendar' ? <Navigate to={'/Calendar'} replace={true}/> :
                    defaultPage === 'Home' ? <Navigate to={'/Home'} replace={true}/> :
                        defaultPage === 'Chemical Database' ? <Navigate to={'/Chems'} replace={true}/> :
                            defaultPage === 'Spray Database' ? <Navigate to={'/Sprays'} replace={true}/> :
                                // Add more conditions for other default pages as needed
                                <Navigate to={'/Home'} replace={true}/> // Default to home page if defaultPage is null or empty
            )}

            <Link to={'/landing'} className="animate-slideInLeft absolute flex top-4 hover:text-green-600 text-white left-4 text-sm p-2 rounded-full transition duration-200">
                <ArrowLongLeftIcon className={`w-4 mr-2`}/>
                Return to Home
            </Link>


            <img
                alt=""
                src={course}
                className="absolute inset-0 object-cover -z-10 size-full"
            />

            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-green-600 to-green-400 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>


            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="animate-slideInTop sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        alt="Your Company"
                        src={logo}
                        className="mx-auto h-14 w-auto"
                    />
                    <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-white">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 animate-slideInBottom sm:mx-auto sm:w-full sm:max-w-[480px]">

                    <div className="bg-white/30 backdrop-blur-sm px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form action="#" onSubmit={onSubmit} method="POST" className="space-y-6">

                            {/*Email*/}
                            <div>
                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                        autoComplete="email"
                                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                    />
                                </div>
                            </div>

                            {/*Password*/}
                            <div>
                                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        value={password} onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                        autoComplete="current-password"
                                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex gap-3">
                                    <div className="flex h-6 shrink-0 items-center">
                                        <div className="group grid size-4 grid-cols-1">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-green-600 checked:bg-green-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                            />
                                            <svg
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                                            >
                                                <path
                                                    d="M3 8L6 11L11 3.5"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="opacity-0 group-has-[:checked]:opacity-100"
                                                />
                                                <path
                                                    d="M3 7H11"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <label htmlFor="remember-me" className="block text-sm/6 text-gray-900">
                                        Remember me
                                    </label>
                                </div>

                                <div className="text-sm/6">
                                    <Button onClick={() => setShowResetPopup(true)}
                                            className="font-semibold hover:text-opacity-50">
                                        Forgot password?
                                    </Button>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSigningIn}
                                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>

                        {/*<div>*/}
                        {/*    <div className="relative mt-10">*/}
                        {/*        <div aria-hidden="true" className="absolute inset-0 flex items-center">*/}
                        {/*            <div className="w-full border-t border-gray-200"/>*/}
                        {/*        </div>*/}
                        {/*        <div className="relative flex justify-center text-sm/6 font-medium">*/}
                        {/*            <span className="bg-white px-6 text-gray-900">Or continue with</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="mt-6 grid grid-cols-2 gap-4">*/}
                        {/*        <a*/}
                        {/*            href="#"*/}
                        {/*            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"*/}
                        {/*        >*/}
                        {/*            <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">*/}
                        {/*                <path*/}
                        {/*                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"*/}
                        {/*                    fill="#EA4335"*/}
                        {/*                />*/}
                        {/*                <path*/}
                        {/*                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"*/}
                        {/*                    fill="#4285F4"*/}
                        {/*                />*/}
                        {/*                <path*/}
                        {/*                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"*/}
                        {/*                    fill="#FBBC05"*/}
                        {/*                />*/}
                        {/*                <path*/}
                        {/*                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"*/}
                        {/*                    fill="#34A853"*/}
                        {/*                />*/}
                        {/*            </svg>*/}
                        {/*            <span className="text-sm/6 font-semibold">Google</span>*/}
                        {/*        </a>*/}

                        {/*        <a*/}
                        {/*            href="#"*/}
                        {/*            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"*/}
                        {/*        >*/}
                        {/*            <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true"*/}
                        {/*                 className="size-5 fill-[#24292F]">*/}
                        {/*                <path*/}
                        {/*                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"*/}
                        {/*                    clipRule="evenodd"*/}
                        {/*                    fillRule="evenodd"*/}
                        {/*                />*/}
                        {/*            </svg>*/}
                        {/*            <span className="text-sm/6 font-semibold">GitHub</span>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <p className="mt-10 text-center text-sm/6 text-gray-500">
                        Not a member?{' '}
                        <a href="/register" className="font-semibold text-green-600 hover:text-opacity-50">
                            Sign Up Now!
                        </a>
                    </p>
                </div>
            </div>


            {/* Reset Password Popup */}
            {showResetPopup && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-bgcolor p-8 rounded-lg shadow-lg">
                        <h2 className="text-xl text-white font-semibold mb-4">Reset Password</h2>
                        <form onSubmit={handleResetSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                className="w-full px-3 py-2 mb-4 mt-1 text-white bg-transparent outline-none border-2 border-bgcard focus:border-bgcard shadow-sm rounded-lg transition duration-300"
                                required
                            />
                            <div className="flex justify-end">
                                <button type="submit" className="px-4 py-2 bg-darkgreen text-white rounded-lg">Reset
                                    Password
                                </button>
                                <button onClick={() => setShowResetPopup(false)}
                                        className="ml-4 px-4 py-2 bg-red-700 text-white rounded-lg">Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Login
