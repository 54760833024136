import React, {useEffect, useState} from 'react';
import {useAuth} from '../../contexts/authContext';
import Dashboard from '../../components/Dashboard';
import {db} from '../../firebase/firebase';
import {
    faCaretDown,
    faCaretUp,
    faDownload,
    faEdit,
    faFileExcel,
    faMagnifyingGlass, faPlus,
    faSquareCaretLeft,
    faSquareCaretRight,
    faTrash,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {utils as xlsxUtils, write as writeXlsx} from 'xlsx';
import {saveAs} from 'file-saver';
import imageSrc from '../../FleetID_Tag_Icon.png'; // Corrected import path
import {Bar, Line} from 'react-chartjs-2';
import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js';
import {
    ArrowDownCircleIcon,
    ArrowPathIcon, ArrowRightCircleIcon,
    ArrowUpCircleIcon,
    DocumentArrowDownIcon, MagnifyingGlassIcon, PlusCircleIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";
import MachineCard from "./MachineCard";
import {Button, Dialog, DialogPanel, DialogTitle, Input, Select} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/16/solid";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const fleetOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Spray Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Years',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

export const fleetOptions2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: false,
                text: 'Machine Name',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Service Cost',
                color: 'white',
                font: {
                    size: 12,
                },
            },
        },
    },
};

const Fleet = () => {
    const { currentUser } = useAuth();
    const [fleet, setFleet] = useState([]);
    const [fleetSide, setFleetSide] = useState(false);
    const [filteredFleet, setFilteredFleet] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        manufacturer: '',
        model: '',
        purchaseDate: '',
        year: 0,
        usage: 0,
        usageUnit: '',
        type: '',
        purchasePrice: 0,
        service: [],
        engineType: '',
        tag: false,
    });
    const [editingFleetId, setEditingFleetId] = useState(null);

    const { darkMode } = useDarkMode();

    const onUpdate = async () => {
        try {
            const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
            const snapshot = await fleetCollectionRef.get();
            const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFleet(fleetData);
            setFilteredFleet(fleetData);
            localStorage.setItem(`fleetData_${currentUser.uid}`, JSON.stringify(fleetData));
        } catch (error) {
            console.error('Error updating fleet data:', error);
        }
    };

    useEffect(() => {
        const fetchFleet = async () => {
            if (currentUser) {
                try {
                    const storedFleetData = localStorage.getItem(`fleetData_${currentUser.uid}`);
                    if (storedFleetData) {
                        const fleetData = JSON.parse(storedFleetData);
                        setFleet(fleetData);
                        setFilteredFleet(fleetData);
                    } else {
                        const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
                        const snapshot = await fleetCollectionRef.get();
                        if (!snapshot.empty) {
                            const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setFleet(fleetData);
                            setFilteredFleet(fleetData);
                            localStorage.setItem(`fleetData_${currentUser.uid}`, JSON.stringify(fleetData));
                        } else {
                            console.log('No fleet found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching fleet:', error);
                }
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setFleetSide(userData.fleetSide);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setFleetSide(userData.fleetSide);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        fetchFleet();
    }, [currentUser]);

    const deleteMachineFromFirestore = async (machineId) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId).delete();
            console.log('Fleet item deleted successfully');
            // Call the onUpdate callback function to trigger UI update
            await onUpdate();
        } catch (error) {
            console.error('Error deleting Fleet item:', error);
        }
    };


    const fillFormWithFleetData = (machine) => {
        setFormData({
            name: machine.name,
            manufacturer: machine.manufacturer,
            model: machine.model,
            purchaseDate: machine.purchaseDate,
            year: machine.year,
            usage: machine.usage,
            usageUnit: machine.usageUnit,
            type: machine.type,
            purchasePrice: machine.purchasePrice,
            service: machine.service,
            engineType: machine.engineType,
            tag: machine.tag,
        });
        setEditingFleetId(machine.id);
        if (isCollapsed === true){
            toggleCollapse();
        }
    };

    const clearForm = () => {
        setFormData({
            name: '',
            manufacturer: '',
            model: '',
            purchaseDate: '',
            year: 0,
            usage: 0,
            usageUnit: '',
            type: '',
            purchasePrice: 0,
            service: [],
            engineType: '',
            tag: false,
        });
        setEditingFleetId(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const collectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');

        const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');
        const formDataWithDate = {
            ...formData,
            purchaseDate: formData.purchaseDate || currentDate
        };


        const parsedFormData = {
            ...formDataWithDate,
            year: parseFloat(formData.year),
            usage: parseFloat(formData.usage),
            purchasePrice: parseFloat(formData.purchasePrice),
        };

        try {
            if (editingFleetId) {
                await collectionRef.doc(editingFleetId).update(parsedFormData);
                console.log('Machine updated successfully!');
                await onUpdate()
            } else {
                // eslint-disable-next-line no-unused-vars
                const docRef = await collectionRef.add(parsedFormData);
                console.log('Machine added successfully!');
                await onUpdate()
            }

            const snapshot = await collectionRef.get();
            const updatedFleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFleet(updatedFleetData);

            clearForm(); // Clear the form after submission
        } catch (error) {
            console.error('Error adding/updating chem: ', error);
        }
    };

    const calculateTotalPrice = (machine) => {
        return (machine.purchasePrice);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'purchaseDate') {
            // If the changed field is 'date', directly set the value
            setFormData((prevData) => ({
                ...prevData,
                purchaseDate: value,
            }));
        } else {
            // For other fields, update as usual
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };



    const handleToggleChange = () => {
        setFormData(prevData => ({
            ...prevData,
            tag: !prevData.tag,
        }));
    };





    // Function to filter machines based on search query
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = fleet.filter(machine =>
            machine.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredFleet(filtered);
    };

    const filterFleetByType = (type) => {
        setSelectedType(type);
        const filtered = fleet.filter(fleet => type === '' || fleet.type === type);
        setFilteredFleet(filtered);
    };




    const currentDate = new Date().toLocaleDateString('en-US').split('/').join('-');


    const calculateTotalCost = (machine) => {

        const totalServiceCost = machine.service.reduce((accumulator, current) => {
            return accumulator + (current.serviceCost);
        }, 0).toFixed(2);

        return (totalServiceCost);
    };


    const downloadAsExcel = () => {
        const columnHeaders = ['Name', 'Manufacturer', 'Purchase Date', 'Year', 'Usage', 'Usage Unit', 'Type', 'Purchase Price', 'Service', 'Engine Type', 'Tag'];
        const worksheetData = [columnHeaders, ...fleet.map(machine => [machine.name, machine.manufacturer, machine.purchaseDate, machine.year, machine.usage, machine.usageUnit, machine.type, machine.purchasePrice, machine.service, machine.engineType, machine.tag])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Machines');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'MachineInventory.xlsx');
    };

    const addOrUpdateButtonText = editingFleetId ? 'Update Machine' : 'Add Machine';

    const [isCollapsed, setIsCollapsed] = useState(false); // Default value

    useEffect(() => {
        setIsCollapsed(fleetSide); // Set isCollapsed to the value of chemSide from Firebase
    }, [fleetSide]); // Run this effect whenever chemSide changes

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const calculateAverageUsage = (machines) => {
        const totalUsage = machines.reduce((accumulator, machine) => {
            const usage = machine.usage;
            return accumulator + usage;
        }, 0);

        const averageUsage = totalUsage / filteredFleet.length;
        return averageUsage.toFixed(2);
    };

    const calculateAverageCost = (machines) => {
        const totalCost = machines.reduce((accumulator, machine) => {
            const serviceCost = parseFloat(calculateTotalCost(machine));
            return accumulator + serviceCost;
        }, 0);

        const average = totalCost / filteredFleet.length
        return average.toFixed(2);
    };

    const calculateModeYear = (machines) => {
        const yearFrequency = machines.reduce((acc, machine) => {
            const year = machine.year;
            acc[year] = (acc[year] || 0) + 1;
            return acc;
        }, {});

        let modeYear = null;
        let maxFrequency = 0;

        for (const year in yearFrequency) {
            if (yearFrequency[year] > maxFrequency) {
                maxFrequency = yearFrequency[year];
                modeYear = year;
            }
        }

        return modeYear;
    };

    const calculateYearsSincePurchase = (machine) => {
        const currentYear = new Date().getFullYear();

        const purchaseYear = new Date(machine.purchaseDate).getFullYear();
        return currentYear - purchaseYear;
    };

    const calcYears = () => {
        return filteredFleet.map(machine => ({
            name: machine.name,
            years: calculateYearsSincePurchase(machine)
        }));
    };

    const averageUsage = calculateAverageUsage(filteredFleet);
    const averageTotalCost = calculateAverageCost(filteredFleet);
    const modeYear = calculateModeYear(filteredFleet);

    const years = calcYears(filteredFleet);

    const calcCost = () => {
        return filteredFleet.map(machine => ({
            name: machine.name,
            cost: calculateTotalCost(machine)
        }));
    };

    // const uniqueNutrients = calculateNutrients();
    const costs = calcCost();

    const fleetBarChartData = {
        labels: years.map(machine => machine.name.slice(0, 5)),
        datasets: [
            {
                label: 'Age (Years)',
                backgroundColor: 'rgba(122, 197, 158, 0.2)',
                borderColor: 'rgba(122, 197, 159, 0.8)',
                borderWidth: 2,
                data: years.map(machine => machine.years),
            }
        ]
    };

    const fleetBarChartData2 = {
        labels: costs.map(machine => machine.name.slice(0,5)),
        datasets: [
            {
                label: 'Service Cost',
                backgroundColor: 'rgba(85, 145, 180, 0.2)',
                borderColor: 'rgba(85, 145, 180, 1)',
                borderWidth: 2,
                data: costs.map(machine => machine.cost),
            }
        ]
    };


    const [expanded, setExpanded] = useState(false); // State to track expansion

    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    return (
        <div className={`${darkMode ? "bg-bgpage text-white" : "bg-bgcolorLight text-black"} flex h-full w-full mt-16`}>


            <Dashboard/>


            <div className="w-full z-20 flex">


                <div className="content pr-3 pt-3 pb-3 w-full">


                    {/*Header*/}
                    <div className="flex flex-col justify-center items-center">


                        <div className="flex items-center justify-center w-full flex-row">

                            <div className="w-1/6 flex items-center text-lg mr-2 tracking-widest">Fleet <span className={`text-xs ml-2`}>[{filteredFleet.length}]</span>

                                <Button onClick={onUpdate}>
                                    <ArrowPathIcon className={`w-4 ml-2 text-gray-500 hover:scale-105 hover:rotate-12 hover:animate-pulse`}/>
                                </Button>

                            </div>

                            {/*Header*/}
                            <div className={`${darkMode ? "bg-bgDark text-white shadow-gray-900" : "bg-white text-black"} flex items-center justify-center rounded-lg p-1 w-full mr-2`}>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Machines:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{filteredFleet.length}</div>
                                    </div>
                                </div>


                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Purchase Price:</div>
                                        <div className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>{"$" + numberWithCommas(filteredFleet.reduce((total, machine) => total + parseFloat(calculateTotalPrice(machine)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <div className="w-full animate-fadeIn">
                                    <div className="flex items-center justify-center">
                                        <div className="text-sm mr-2">Total Service Cost:</div>
                                        <div
                                            className={`text-sm rounded-lg border-2 p-1 border-solid ${darkMode ? 'border-neutral-700' : ''}`}>${numberWithCommas(filteredFleet.reduce((total, machine) => total + parseFloat(calculateTotalCost(machine)), 0).toFixed(2))}</div>
                                    </div>
                                </div>

                                <Button
                                    className="text-xl text-white font-bold mt-auto mb-auto hover:text-litegreen mr-2"
                                    onClick={toggleExpansion}>
                                    {expanded ? <ArrowUpCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/> :
                                        <ArrowDownCircleIcon className={`w-5 ${darkMode ? '' : 'text-black'}`}/>}
                                </Button>

                            </div>

                            <Button onClick={downloadAsExcel}
                                    className={`${darkMode ? "text-white" : "text-gray-400"} flex font-bold rounded-lg focus:outline-none shadow-gray-900`}>
                                <DocumentArrowDownIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                            <Button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}
                                    onClick={toggleCollapse}>
                                <ArrowRightCircleIcon className={`w-6 hover:text-green-500`}/>
                            </Button>

                        </div>


                        {/* Conditionally render content based on expansion state */}
                        {expanded && (
                            <>
                                <div
                                    className={`${darkMode ? "bg-bgcolor shadow-inner shadow-gray-900" : "bg-bgcolorLiteGreen"} mt-2 mr-2 ml-2 p-2 flex items-center justify-center rounded-md w-full`}>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Service Cost Comparison:</div>
                                            <Bar options={fleetOptions2} data={fleetBarChartData2}
                                                 className="mr-auto ml-auto"/>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col">

                                        <div className="flex flex-row mb-2">

                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} w-full mr-2 rounded-md p-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Usage:</div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>{numberWithCommas(averageUsage)}</div>
                                                </div>
                                            </div>


                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} w-full mr-2 rounded-md p-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Model Year:
                                                    </div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>{modeYear}</div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="flex flex-row mb-2">

                                            <div
                                                className={`${darkMode ? "bg-bglite shadow-inner shadow-gray-900" : "bg-white"} mr-auto ml-auto rounded-md pl-6 pr-6 pb-1 pt-1`}>
                                                <div className="flex flex-col items-center justify-center m-1">
                                                    <div className="text-sm text-litegreen mb-1">Average Service Cost:
                                                    </div>
                                                    <div
                                                        className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen`}>${numberWithCommas(averageTotalCost)}</div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>


                                    <div className="w-full max-w-screen-md h-full">
                                        <div className="items-center justify-center flex flex-col">
                                            <div className="text-sm text-litegreen mr-2">Equipment Age:</div>
                                            <Bar options={fleetOptions} data={fleetBarChartData}
                                                 className="ml-auto mr-auto"/>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}


                    </div>


                    {/*Search Bar Row*/}
                    <div className="mt-2 w-full flex">

                        <div className={`w-full flex flex-row items-center ${darkMode ? "bg-bgDark" : "bg-white shadow-sm"} rounded-lg px-2 mr-2`}>
                            <MagnifyingGlassIcon className={`w-4 mr-2`}/>
                            <Input
                                type="text"
                                placeholder="Search fleet by name"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                className={`rounded-lg block w-full bg-transparent`}
                            />
                        </div>

                        <div className="w-1/4 flex flex-row items-center">
                            <Select
                                value={selectedType}
                                onChange={(e) => filterFleetByType(e.target.value)}
                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-bgDark dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                            >
                                <option value="">All Types</option>

                                <option value="Greens Mower">Greens Mower</option>
                                <option value="Tees Mower">Tees Mower</option>
                                <option value="Fairway Mower">Fairway Mower</option>
                                <option value="Rough Mower">Rough Mower</option>
                                <option value="Bunker Rake">Bunker Rake</option>
                                <option value="Work Cart">Work Cart</option>
                                <option value="Ball Picker">Ball Picker</option>
                                <option value="Utility Vehicle">Utility Vehicle</option>
                                <option value="Tow Blower">Tow Blower</option>
                                <option value="Tractor">Tractor</option>
                                <option value="Truck">Truck</option>
                                <option value="Misc.">Misc.</option>
                                {/* Add more options as needed */}
                            </Select>

                        </div>

                    </div>


                    {/*Fleet Body*/}
                    <div className={` ${expanded ? 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-23.4rem)] scrollbar-none' : 'mt-2 w-full rounded-md overflow-y-auto max-h-[calc(100vh-11.25rem)] scrollbar-none'}`}>


                        {filteredFleet.length === 0 ? (
                            // Display custom dialog if sprays array is empty
                            <div className="flex items-center justify-center h-full">
                                <div
                                    className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10`}>
                                    <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding a Machine Through the App</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Fill out
                                        the panel on the right hand side of the screen</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] Scroll
                                        down on the panel until you see the Add Machine button</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Press the Add Machine button to add it to your database</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>4] If your spray does not automatically show up on the screen refresh your page and it should appear</p>

                                    <p className="text-lg text-litegreen mt-2">Adding a Machine through the ID Tag</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Open up the ChemBase scanner app on your mobile phone</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] Make sure you have NFC enabled on your device and that your device is NFC compatible</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Tap the ChemBase ID Tag to the back of your mobile phone</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>4] Fill out the form that pops up on your device and press add Machine</p>
                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>5] You should be able to see your machine now pop up on the Web and or Mobile ChemBase applications</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Make sure you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            // Display SprayCard components if sprays array is not empty
                            filteredFleet.map((machine, index) => (
                                <MachineCard key={index} machine={machine}
                                             onDelete={() => deleteMachineFromFirestore(machine.id, onUpdate())}
                                             onEdit={fillFormWithFleetData}/>
                            ))
                        )}


                    </div>


                </div>


            </div>





            <Dialog open={isCollapsed} onClose={setIsCollapsed} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 mt-[4.75rem] pb-3 pr-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex h-full flex-col overflow-y-scroll ${darkMode ? 'bg-bgDark' : 'bg-white'} rounded-lg shadow-2xl`}>


                                    {/*Drawer Header*/}
                                    <div className={`${darkMode ? 'bg-bgcard/30 border-neutral-700' : 'bg-bgcolorLight'} border-b-4 border-dashed px-4 py-6 sm:px-6`}>
                                        <div className="flex items-center justify-between">
                                            <Button type="button" onClick={clearForm} className="font-bold py-1 px-1 rounded-lg mr-2 focus:outline-none focus:shadow-outline animate-fadeIn">
                                                <XCircleIcon className="w-6 hover:text-red-700 flex-none" aria-hidden="true"/>
                                            </Button>


                                            <DialogTitle className="text-base font-semibold tracking-widest">Add Machine</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setIsCollapsed(false)}
                                                    className="relative rounded-md hover:text-green-500"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <p className="text-xs text-green-500">This is the form for adding new chemicals to your database. Once filled out you will see it appear to your left.</p>
                                        </div>
                                    </div>


                                    {/*Drawer Content*/}
                                    <div className="relative flex-1 px-4 sm:px-6">


                                        <form onSubmit={handleSubmit} className="mt-2 rounded-lg overflow-y-auto max-h-[calc(100vh-9.8rem)] scrollbar-none animate-fadeIn">

                                            <div className="text-white justify-center items-center text-center text-xs">IDENTIFIERS</div>

                                            <div className="flex items-center justify-center mt-2 mb-2">

                                                <label className="block text-white text-sm mb-2 mr-5" htmlFor="name">
                                                    Name:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="manufacturer">
                                                    Manufacturer:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="manufacturer"
                                                    name="manufacturer"
                                                    value={formData.manufacturer}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="model">
                                                    Model:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="model"
                                                    name="model"
                                                    value={formData.model}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm w-full"
                                                       htmlFor="purchaseDate">
                                                    Purchase Date:
                                                </label>

                                                <input
                                                    type="text"
                                                    id="purchaseDate"
                                                    name="purchaseDate"
                                                    value={formData.purchaseDate || currentDate}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="text-white mt-4 justify-center items-center text-center text-xs">CLASSIFIERS
                                            </div>


                                            <div className="flex items-center justify-start mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="type">
                                                    Machine Type:
                                                </label>

                                                <select
                                                    id="type"
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bglite shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-1/2 py-1 px-3 leading-tight shadow-gray-900`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Greens Mower">Greens Mower</option>
                                                    <option value="Tees Mower">Tees Mower</option>
                                                    <option value="Fairway Mower">Fairway Mower</option>
                                                    <option value="Rough Mower">Rough Mower</option>
                                                    <option value="Bunker Rake">Bunker Rake</option>
                                                    <option value="Work Cart">Work Cart</option>
                                                    <option value="Ball Picker">Ball Picker</option>
                                                    <option value="Utility Vehicle">Utility Vehicle</option>
                                                    <option value="Tow Blower">Tow Blower</option>
                                                    <option value="Tractor">Tractor</option>
                                                    <option value="Truck">Truck</option>
                                                    <option value="Misc.">Misc.</option>
                                                </select>
                                            </div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-white text-sm mr-5" htmlFor="engineType">
                                                    Engine Type:
                                                </label>

                                                <select
                                                    id="engineType"
                                                    name="engineType"
                                                    value={formData.engineType}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bglite shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-1/2 py-1 px-3 leading-tight shadow-gray-900`}
                                                >
                                                    <option value="">Select...</option>

                                                    <option value="Gasoline">Gasoline</option>
                                                    <option value="Diesel">Diesel</option>
                                                    <option value="Electric">Electric</option>
                                                    <option value="Hybrid">Hybrid</option>

                                                </select>
                                            </div>


                                            <div className="flex items-center justify-center mt-2 mb-2">
                                                <label className="block text-white text-sm mr-5" htmlFor="year">
                                                    Year:
                                                </label>

                                                <input
                                                    type="number"
                                                    id="year"
                                                    name="year"
                                                    value={formData.year}
                                                    onChange={handleChange}
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to identify this chemical in your
                                                database
                                            </div>


                                            <div
                                                className="text-white mt-4 justify-center items-center text-center text-xs mb-2">MEASUREMENTS
                                            </div>


                                            <div className="flex mb-2">
                                                <div className="w-full mr-5">
                                                    <label className="block text-white text-sm mb-2" htmlFor="usage">
                                                        Usage:
                                                    </label>

                                                    <input
                                                        type="number"
                                                        id="usage"
                                                        name="usage"
                                                        value={formData.usage}
                                                        onChange={handleChange}
                                                        className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                    />
                                                </div>

                                                <div className="w-full">
                                                    <label className="block text-white text-sm mb-2"
                                                           htmlFor="usageUnit">
                                                        Usage Unit:
                                                    </label>

                                                    <select
                                                        id="usageUnit"
                                                        name="usageUnit"
                                                        value={formData.usageUnit}
                                                        onChange={handleChange}
                                                        className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                    >

                                                        <option value="">Select...</option>

                                                        <option value="Hours">Hours</option>
                                                        <option value="Miles">Miles</option>

                                                    </select>
                                                </div>
                                            </div>


                                            <div
                                                className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This
                                                is the data that the system uses to calculate your volume
                                            </div>


                                            <div className="text-white mt-4 justify-center items-center text-center text-xs">PRICE</div>


                                            <div className="flex items-center mt-2 mb-2 justify-start">
                                                <label className="block text-white text-sm w-full" htmlFor="purchasePrice">
                                                    Purchase Price:
                                                </label>

                                                <input
                                                    type="number"
                                                    id="purchasePrice"
                                                    name="purchasePrice"
                                                    value={formData.purchasePrice}
                                                    onChange={handleChange}
                                                    step="0.01"
                                                    className={`${darkMode ? "bg-bgcard shadow-inner text-white" : "bg-white text-black drop-shadow"} appearance-none rounded-md w-full py-1 px-3 leading-tight shadow-gray-900`}
                                                />
                                            </div>


                                            <div className={`${darkMode ? "text-darkgreen" : "text-gray-400"} mt-2 mb-4 font-bold justify-center items-center text-center text-xxs`}>This is the data that the system uses to calculate your cost analysis</div>


                                            <div className="flex items-center justify-center mt-4 mb-4 relative">
                                                <label htmlFor="tag" className="text-white text-sm cursor-pointer mr-5">Id Tag:</label>
                                                <input
                                                    type="checkbox"
                                                    id="tag"
                                                    name="tag"
                                                    className="sr-only"
                                                    checked={formData.tag}
                                                    onChange={handleToggleChange}
                                                />
                                                <label htmlFor="tag" className="cursor-pointer">
                                                    <div className={`w-14 h-8 bg-gray-600 rounded-full relative ${formData.tag ? 'bg-green-500' : 'bg-red-500'}`}>
                                                        <div
                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${formData.tag ? 'bg-white translate-x-full' : 'bg-white'}`}></div>
                                                    </div>
                                                </label>
                                            </div>

                                            {/* Add more input fields as needed */}
                                            <div className="flex w-full justify-center mt-4">
                                                {/* Add or Update Chem button */}
                                                <button type="submit"
                                                        className="w-full bg-darkgreen hover:bg-litegreen text-white text-sm font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline">
                                                    {addOrUpdateButtonText}
                                                </button>
                                            </div>


                                        </form>


                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>




        </div>
    );
};

export default Fleet;
