import {useAuth} from "../../contexts/authContext";
import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {db} from "../../firebase/firebase";
import {Pie} from "react-chartjs-2";
import NutrientCard from "./NutrientCard";
import IngredientCard from "./IngredientCard";
import {Button} from "@headlessui/react";
import {
    ArrowDownCircleIcon,
    ArrowPathRoundedSquareIcon,
    ArrowUpCircleIcon,
    PencilSquareIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

const SprayCard = ({spray, onDelete, onEdit}) => {
    const {currentUser} = useAuth();
    const [expanded, setExpanded] = useState(false); // State to track expansion
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const { darkMode } = useDarkMode();

    const [alertMessage, setAlertMessage] = useState(null);

    const showAlert = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage(null);
        }, 2000); // Display for 2 seconds
    };

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        onDelete(spray.id);
        setShowDeleteConfirmation(false);
    };


    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const areaUnit = spray.areaUnit;
    const area = spray.area;
    const tankNum = spray.tankNum;

    let areaNum;

    if (areaUnit === "acres") {
        areaNum = area * 43560; // Convert acres to square feet
    } else {
        areaNum = area; // Assume the area is already in square feet
    }

    const calculateNutrients = () => {

        const nutrientsMap = {};

        spray.ingredients.forEach(ingredient => {
            const volumeOZ = (areaNum * (ingredient.rate / 1000));
            const volumeGal = (volumeOZ / 128);

            const weight = ingredient.weight;

            ingredient.nutrients.forEach(nutrient => {
                const {name, percentage} = nutrient;
                const value = (((percentage / 100) * weight) * volumeGal);

                if (!nutrientsMap[name]) {
                    nutrientsMap[name] = parseFloat(value); // Parse value to number
                } else {
                    nutrientsMap[name] += parseFloat(value); // Parse value to number and add
                }
            });
        });

        return Object.entries(nutrientsMap).map(([name, totalValue]) => ({name, totalValue}));
    };


    const uniqueNutrients = calculateNutrients();

    // console.log(uniqueNutrients)

    const nutrientChart = {
        labels: uniqueNutrients.map(nutrient => nutrient.name),
        datasets: [
            {
                label: 'lbs',
                data: uniqueNutrients.map(nutrient => nutrient.totalValue.toFixed(2)),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderWidth: 1,
            },
        ],
    };


    const totalNutrientWeight = uniqueNutrients.reduce((accumulator, current) => {
        return accumulator + (current.totalValue);
    }, 0).toFixed(2);


    const totalChemVol = spray.ingredients.reduce((accumulator, current) => {
        return accumulator + (areaNum * ((current.rate / 1000) / 128));
    }, 0).toFixed(2);

    const totalChemPrice = spray.ingredients.reduce((accumulator, current) => {
        return accumulator + (current.price * (areaNum * ((current.rate / 1000) / 128)));
    }, 0).toFixed(2);


    const updateChemsDatabase = async () => {
        const batch = db.batch(); // Using batched writes for efficiency

        try {
            await Promise.all(spray.ingredients.map(async (ingredient, index) => {
                console.log(`Processing ingredient ${index + 1}/${spray.ingredients.length}`);
                console.log(`Ingredient:`, ingredient);

                // Ensure the ingredient has the 'chemID' property
                if (!ingredient.hasOwnProperty('chemID')) {
                    console.error(`Ingredient ${index + 1} does not have a 'chemID' property.`);
                    return; // Skip to the next iteration
                }

                const chemID = ingredient.chemID;

                const volumeOZ = areaNum * (ingredient.rate / 1000);
                const volumeGal = (volumeOZ / 128).toFixed(2);

                console.log(`Calculating for ingredient ${ingredient.chemID}:`);
                console.log(`Volume in gallons: ${volumeGal}`);

                // Reference to the document in the 'chems' collection
                const chemDocRef = db.collection('Users').doc(currentUser.uid).collection('chems').doc(chemID);

                // Get the current chem data
                const chemDocSnapshot = await chemDocRef.get();

                if (chemDocSnapshot.exists) {
                    let currentQuantity = chemDocSnapshot.data().quantity;
                    let currentExcess = chemDocSnapshot.data().excess || 0; // Default to 0 if excess doesn't exist

                    const containersNeededFinal = ((volumeGal) / ingredient.containerVol).toFixed(2);
                    console.log(`Containers needed: ${containersNeededFinal}`);

                    // Calculate how many containers we need
                    const containersNeeded = Math.floor((volumeGal - currentExcess) / ingredient.containerVol);
                    console.log(`Containers needed: ${containersNeeded}`);

                    // Subtract containersNeeded from quantity
                    const newQuantity = currentQuantity - containersNeeded;
                    console.log(`New quantity: ${newQuantity}`);

                    // Calculate the remaining excess
                    const remainingExcess = parseFloat(((volumeGal) % ingredient.containerVol).toFixed(2));
                    console.log(`Remaining excess: ${remainingExcess}`);


                    // Update the quantity and excess fields
                    batch.update(chemDocRef, {
                        quantity: newQuantity,
                        excess: remainingExcess
                    });
                } else {
                    console.log(`Chem with ID ${ingredient.chemID} does not exist`);
                }
            }));

            // Update the 'updated' field of the current spray to true
            const sprayDocRef = db.collection('Users').doc(currentUser.uid).collection('sprays').doc(spray.id);
            batch.update(sprayDocRef, {updated: true});

            // Commit the batched writes
            await batch.commit();
            console.log('Chem quantities and excess updated successfully');
            showAlert('Chemical Database Updated Successfully!');

        } catch (error) {
            console.error('Error updating chem quantities and excess:', error);
        }
    };


    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (

        <div className={`${darkMode ? "bg-bgDark text-white" : "bg-white drop-shadow text-black"} p-2 rounded-md items-center justify-center shadow-gray-900 mb-2 w-full animate-cascade`}>


            <div className="relative">
                {/* Your existing UI components */}
                {alertMessage && (
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-bglite text-white p-4 rounded-lg shadow-inner shadow-gray-900">
                        {alertMessage}
                    </div>
                )}
            </div>


            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} p-4 rounded-md`}>
                        <p className={`${darkMode ? "text-white" : "text-black"}`}>Are you sure you want to delete this Spray?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center">


                {/*Delete Button*/}
                <div className="mr-2">
                    <Button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <TrashIcon className="w-5 text-red-700 hover:text-red-500 hover:animate-pulse"/>
                    </Button>
                </div>


                {/*Section 1*/}
                <div className={`${darkMode ? "" : "drop-shadow"} bg-green-600/15 border border-green-600 h-full rounded-md flex flex-col items-center justify-center p-2 w-1/4 shadow-gray-900 mr-5`}>
                    <div className="text-lg tracking-widest text-center line-clamp-1">{spray.name}</div>
                    <div className="text-xs text-gray-500 text-center line-clamp-1">{spray.purpose}</div>
                    <div className="text-xxs text-gray-500 text-center line-clamp-1">{spray.date}</div>
                </div>


                {/*Section 2 & 3*/}
                <div className="h-full w-1/2 ">

                    {/*Section 2*/}
                    <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-md flex p-2 w-full shadow-gray-900 mr-5 mb-2.5`}>

                        <div className="w-full mr-3">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Tanks:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>{tankNum}</div>
                        </div>

                        <div className="w-full mr-3">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Ingredients:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>{spray.ingredients.length}</div>
                        </div>

                        <div className="w-full mr-3">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Tank Vol:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>{spray.tankVol + " gal"}</div>
                        </div>

                        <div className="w-full">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Target:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>{spray.target}</div>
                        </div>

                    </div>

                    {/*Section 3*/}
                    <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-md flex p-2 w-full shadow-gray-900 mr-5`}>

                        <div className="w-full flex mr-5 items-center justify-center">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1 mr-2`}>Area:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>{area + " " + areaUnit}</div>
                        </div>

                        <div className="w-full flex items-center justify-center">
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mr-2 line-clamp-1`}>Cost Per Acre:</div>
                            <div className={`rounded-md text-sm text-center border border-1 p-0.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>${numberWithCommas((totalChemPrice / area).toFixed(2))}</div>
                        </div>

                    </div>

                </div>


                {/*Section 4*/}
                <div className="h-full rounded-md flex items-center justify-center w-1/4">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Total Volume:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-md mb-2 text-center line-clamp-1`}>{numberWithCommas((tankNum * spray.tankVol).toFixed(2)) + " gal"}</div>

                        <div className={`${darkMode ? "shadow-inner" : "drop-shadow"} rounded-md border p-1.5 border-solid border-green-600 bg-green-600/15 shadow-gray-900`}>
                            <div className="text-lg text-darkgreen text-center line-clamp-1">Total Price:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center line-clamp-1`}>${numberWithCommas(totalChemPrice)}</div>
                        </div>

                    </div>


                </div>


                {/*Buttons End*/}
                <div className="flex flex-col items-center">


                    <Button onClick={() => onEdit(spray)} className={`${darkMode ? "text-white" : "text-gray-400"} text-md px-2 mb-4 hover:text-litegreen`}>
                        <PencilSquareIcon className={`w-6`}/>
                    </Button>

                    <Button className={`${darkMode ? "text-white" : "text-gray-400"} text-xxl font-bold mt-auto mb-auto hover:text-litegreen`}
                            onClick={toggleExpansion}>
                        {expanded ?
                            <ArrowUpCircleIcon className={`w-6`}/>
                            :
                            <ArrowDownCircleIcon className={`w-6`}/>
                        }
                    </Button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <>

                    {/*Tank Data Label*/}
                    <div className={`border-b-4 border-dotted ${darkMode ? 'border-neutral-700' : ''} pb-2`}>
                        <div
                            className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5`}>TANK
                            DATA
                        </div>
                        <div className={`text-green-600 text-xs text-center`}>All of this data is per individual tank
                        </div>
                    </div>


                    {/*Tank Data*/}
                    <div className={`${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-lg flex p-2 w-full shadow-gray-900 mt-5`}>

                        <div
                            className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Amount of
                                Chems:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{(totalChemVol / tankNum).toFixed(2)} gal
                            </div>
                        </div>

                        <div
                            className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Amount of
                                Water:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{(spray.tankVol - (totalChemVol / tankNum)).toFixed(2)} gal
                            </div>
                        </div>

                        <div
                            className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Cost:</div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>${(totalChemPrice / tankNum).toFixed(2)}</div>
                        </div>

                        <div
                            className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Spray
                                Rate:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{spray.rate + " Gal / Acre"}</div>
                        </div>

                        <div
                            className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full`}>
                            <div className="text-xs text-darkgreen font-bold text-center mb-1 line-clamp-1">Coverage:
                            </div>
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{(area / tankNum).toFixed(2) + " " + areaUnit}</div>
                        </div>

                    </div>


                    {/*Chemical Data Label*/}
                    <div className={`flex border-b-4 border-dotted ${darkMode ? 'border-neutral-700' : ''} pb-2`}>

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className="w-full items-center justify-center">
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5`}>CHEMICAL
                                DATA
                            </div>
                            <div className={`${darkMode ? "text-darkgreen" : "text-gray-400"} text-xs text-center`}>All
                                of this data is the amounts of each Chemical
                            </div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">
                            <Button
                                onClick={updateChemsDatabase}
                                className={`text-white flex items-center text-xs px-2 py-1 rounded-lg focus:outline-none focus:shadow-outline ${
                                    spray.updated ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-opacity-50'
                                }`}
                                disabled={spray.updated}
                            >
                                <ArrowPathRoundedSquareIcon className={`w-5 mr-1`}/>
                                {spray.updated ? 'Already Updated' : 'Update Chemical Inventory'}
                            </Button>
                        </div>


                    </div>


                    {/*Chemical Data*/}
                    <div className={`${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-md p-2 w-full shadow-gray-900 mt-5`}>

                        {/*Ingredient Map*/}
                        <div
                            className="items-center justify-center rounded-2xl overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                            {spray.ingredients.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-5`}>
                                        <p className="text-xl text-darkgreen">This Spray Has No Chemicals</p>
                                        <p className="text-lg text-litegreen mt-1">Adding a Chemical</p>

                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Press
                                            the Edit Button to the left of the Spray Card</p>
                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] This
                                            will fill the panel to the right with your spray's data</p>
                                        <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3]
                                            Navigate to the Chemicals Section and add in your chemicals and then press
                                            Update Spray</p>
                                    </div>
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                spray.ingredients.map((ingredient, index) => (
                                    <IngredientCard key={index} ingredient={ingredient} area={area} areaUnit={areaUnit}
                                                    tankNum={tankNum}/>
                                ))
                            )}

                        </div>

                    </div>


                    {/*Chemical Totals*/}
                    <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white drop-shadow border-1 border"} flex items-center justify-center rounded-lg p-1 shadow-gray-900 mt-2.5 w-3/4 mx-auto`}>

                        <div className="mr-auto ml-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Ingredient:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{spray.ingredients.length}</div>
                            </div>
                        </div>

                        <div className="mr-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Total Chem Volume:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{numberWithCommas(totalChemVol)} gal</div>
                            </div>
                        </div>

                        <div className=" mr-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Total Price:</div>
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>${numberWithCommas(totalChemPrice)}</div>
                            </div>
                        </div>

                    </div>


                    {/*Nutrient Data Label*/}
                    <div className={`flex border-b-4 border-dotted ${darkMode ? 'border-neutral-700' : ''} pb-2`}>

                        <div className="w-full items-center justify-center">

                        </div>

                        <div className=" w-full items-center justify-center">
                            <div
                                className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5`}>NUTRIENT
                                DATA
                            </div>
                            <div className={`${darkMode ? "text-darkgreen" : "text-gray-400"} text-xs text-center`}>All
                                of this data is the amounts of each Nutrient
                            </div>
                        </div>

                        <div className="flex flex-col items-end justify-center w-full mt-5">

                            <div className="flex items-center justify-center">
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-xs mr-2 line-clamp-1`}>Nutrients
                                    per Acre:
                                </div>
                                <div
                                    className={`${darkMode ? "text-white" : "text-black"} text-xs rounded-md border p-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{(totalNutrientWeight / spray.area).toFixed(2)} lbs/acre
                                </div>
                            </div>

                        </div>


                    </div>


                    {/*Nutrient Data*/}
                    <div className={`${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-md p-2 w-full shadow-gray-900 mt-5`}>

                        <div className="w-full flex flex-row">

                            <div className="w-2/3">

                                <div
                                    className="items-center justify-center rounded-2xl overflow-y-auto max-h-[calc(80vh-20rem)] scrollbar-none">

                                    {uniqueNutrients.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center">
                                            <div
                                                className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-5`}>
                                                <p className="text-xl text-darkgreen">This Spray Has No Nutrients</p>
                                                <p className="text-lg text-litegreen mt-1">Potential Issues</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1]
                                                    You did not add in the Chemicals Nutrients in the database</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2]
                                                    Your Chemicals you have added do not contain Nutrients</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        uniqueNutrients.map((nutrient, index) => (
                                            <NutrientCard key={index} nutrient={nutrient} tankNum={tankNum}/>
                                        ))
                                    )}

                                </div>

                            </div>

                            <div className="w-1/3 max-w-screen-md h-full">

                                <Pie data={nutrientChart} className="mr-auto ml-auto"/>

                            </div>

                        </div>

                    </div>


                    {/*Nutrient totals*/}
                    <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white drop-shadow border-1 border"} flex items-center justify-center rounded-lg p-1 shadow-gray-900 mt-2.5 w-3/4 mx-auto`}>

                        <div className="mr-auto ml-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Nutrients:</div>
                                <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{uniqueNutrients.length}</div>
                            </div>
                        </div>

                        <div className="mr-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Tank Nutrient Weight:</div>
                                <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{(totalNutrientWeight / tankNum).toFixed(2)} lbs</div>
                            </div>
                        </div>

                        <div className=" mr-auto">
                            <div className="flex items-center justify-center">
                                <div className="text-sm mr-2 line-clamp-1">Total Nutrient Weight:</div>
                                <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{numberWithCommas(totalNutrientWeight)} lbs</div>
                            </div>
                        </div>

                    </div>


                </>
            )}

        </div>
    );
};

export default SprayCard;
