/* eslint-disable complexity */
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef
} from 'react';

import {GoogleMapsContext} from '@vis.gl/react-google-maps';

function usePolyline(props) {
    const {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseOut,
        encodedPath,
        ...polylineOptions
    } = props;

    const callbacks = useRef({});
    Object.assign(callbacks.current, {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseOut
    });

    const polyline = useRef(null);

    useEffect(() => {
        try {
            if (!window.google) throw new Error("Google Maps API not loaded");
            polyline.current = new window.google.maps.Polyline();
        } catch (error) {
            console.error("Failed to create polyline:", error);
        }
    }, []);

    useEffect(() => {
        if (!polyline.current) return;
        try {
            polyline.current.setOptions(polylineOptions);
        } catch (error) {
            console.error("Failed to set polyline options:", error);
        }
    }, [polylineOptions]);

    const map = useContext(GoogleMapsContext)?.map;

    useEffect(() => {
        if (!map) {
            console.error('<Polyline> must be used within a <Map> component.');
            return;
        }
        try {
            polyline.current.setMap(map);
        } catch (error) {
            console.error("Failed to set polyline on map:", error);
        }

        return () => {
            polyline.current?.setMap(null);
        };
    }, [map]);

    useEffect(() => {
        if (!polyline.current) return;
        const gme = window.google.maps.event;
        try {
            [
                ['click', 'onClick'],
                ['drag', 'onDrag'],
                ['dragstart', 'onDragStart'],
                ['dragend', 'onDragEnd'],
                ['mouseover', 'onMouseOver'],
                ['mouseout', 'onMouseOut']
            ].forEach(([eventName, eventCallback]) => {
                gme.addListener(polyline.current, eventName, (e) => {
                    const callback = callbacks.current[eventCallback];
                    if (callback) callback(e);
                });
            });
        } catch (error) {
            console.error("Failed to attach event listeners:", error);
        }

        return () => {
            gme.clearInstanceListeners(polyline.current);
        };
        // eslint-disable-next-line
    }, [polyline.current, callbacks]);

    return polyline.current;
}

export const Polyline = forwardRef((props, ref) => {
    const polyline = usePolyline(props);

    useImperativeHandle(ref, () => polyline, [polyline]);

    return null;
});
