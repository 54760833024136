import React, {useEffect, useRef, useState} from 'react';
import Dashboard from '../../components/Dashboard';
import { useAuth } from "../../contexts/authContext";
import { db } from "../../firebase/firebase";
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import greensImg from "../../greens.png";
import surroundsImg from "../../surrounds.png";
import collarsImg from "../../collars.png";
import fairwaysImg from "../../fairways.png";
import teesImg from "../../tees.png";
import roughImg from "../../rough.png";
import {useDarkMode} from "../../contexts/darkModeContext";


const Home = () => {
    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [chems, setChems] = useState([]);
    const [sprays, setSprays] = useState([]);
    const [fleet, setFleet] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [address, setAddress] = useState('');

    const [greens, setGreens] = useState('');
    const [surrounds, setSurrounds] = useState('');
    const [collars, setCollars] = useState('');
    const [fairways, setFairways] = useState('');
    const [rough, setRough] = useState('');
    const [tees, setTees] = useState('');

    const [mapCenter, setMapCenter] = useState(null);
    const mapZoom = 16;

    const mapOptions = {
        mapTypeId: 'satellite',
    };


    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                    const snapshot = await chemsCollectionRef.get();

                    if (!snapshot.empty) {
                        const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setChems(chemsData);
                    } else {
                        console.log('No chems found');
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };

        const fetchEmployees = async () => {
            if (currentUser) {
                try {
                    const CollectionRef = db.collection('Users').doc(currentUser.uid).collection('team');
                    const snapshot = await CollectionRef.get();

                    if (!snapshot.empty) {
                        const Data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setEmployees(Data);
                    } else {
                        console.log('No employees found');
                    }
                } catch (error) {
                    console.error('Error fetching employees:', error);
                }
            }
        };

        const fetchSprays = async () => {
            if (currentUser) {
                try {
                    const spraysCollectionRef = db.collection('Users').doc(currentUser.uid).collection('sprays');
                    const snapshot = await spraysCollectionRef.get();

                    if (!snapshot.empty) {
                        const spraysData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setSprays(spraysData);
                    } else {
                        console.log('No sprays found');
                    }
                } catch (error) {
                    console.error('Error fetching sprays:', error);
                }
            }
        };

        const fetchFleet = async () => {
            if (currentUser) {
                try {
                    const fleetCollectionRef = db.collection('Users').doc(currentUser.uid).collection('fleet');
                    const snapshot = await fleetCollectionRef.get();

                    if (!snapshot.empty) {
                        const fleetData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setFleet(fleetData);
                    } else {
                        console.log('No fleet found');
                    }
                } catch (error) {
                    console.error('Error fetching fleet:', error);
                }
            }
        };

        const fetchCoordinates = async () => {
            try {
                const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                    params: {
                        address: address,
                        key: 'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E',
                    },
                });
                const { results } = response.data;
                if (results && results.length > 0) {
                    const { lat, lng } = results[0].geometry.location;
                    setMapCenter({ lat: lat, lng: lng });
                } else {
                    console.error('No coordinates found for the address');
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    // Fetch user document from Firestore based on the current user's UID
                    const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                    if (userDoc.exists) {
                        // Get the user data from the document
                        const userData = userDoc.data();

                        setAddress(userData.address || ""); // Default to false if metric field doesn't exist

                        setGreens(userData.greens || 0); // Default to false if metric field doesn't exist
                        setSurrounds(userData.surrounds || 0); // Default to false if metric field doesn't exist
                        setCollars(userData.collars || 0); // Default to false if metric field doesn't exist
                        setFairways(userData.fairways || 0); // Default to false if metric field doesn't exist
                        setRough(userData.rough || 0); // Default to false if metric field doesn't exist
                        setTees(userData.tees || 0); // Default to false if metric field doesn't exist


                    } else {
                        console.log('User document not found');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();

        fetchSprays();
        fetchChems();
        fetchEmployees();
        fetchFleet();
        fetchCoordinates();
    }, [currentUser, address]);


    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>
            <Dashboard/>
            <div className="z-20 content w-full flex flex-col mr-3">

                <div className="content flex flex-col h-1/7">

                    <div className="justify-center flex flex-row h-full items-center pt-3 pb-3">

                        <div className={`${darkMode ? "bg-bgcolor  shadow-inner" : "bg-white drop-shadow"} w-full max-w-screen-md justify-center items-center rounded-md shadow-gray-900 mr-3 p-2`}>
                            <div className="text-litegreen w-full text-center">Total Chemicals in Database</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} w-full text-center`}>{chems.length} Chemicals</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor  shadow-inner" : "bg-white drop-shadow"} w-full max-w-screen-md justify-center items-center rounded-md shadow-gray-900 mr-3 p-2`}>
                            <div className="text-litegreen w-full text-center">Total Sprays Logged</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} w-full text-center`}>{sprays.length} Sprays</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor  shadow-inner" : "bg-white drop-shadow"} w-full max-w-screen-md justify-center items-center rounded-md shadow-gray-900 mr-3 p-2`}>
                            <div className="text-litegreen w-full text-center">Total Size of Fleet</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} w-full text-center`}>{fleet.length} Machines</div>
                        </div>
                        <div className={`${darkMode ? "bg-bgcolor  shadow-inner" : "bg-white drop-shadow"} w-full max-w-screen-md justify-center items-center rounded-md shadow-gray-900 p-2`}>
                            <div className="text-litegreen w-full text-center">Employees</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} w-full text-center`}>{employees.length} Employees</div>
                        </div>
                    </div>

                </div>

                <div className="content pb-3 flex flex-col h-full">

                    <div className="flex flex-row h-full">


                        <div className={` ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} justify-center flex flex-row h-full w-4/5 items-center rounded-md shadow-gray-900 mr-3`}>

                            {address === "" ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex items-center justify-center h-full">
                                    <div className="bg-bgcolor py-2 rounded-md shadow-inner shadow-gray-900 flex items-center justify-center flex-col m-10">
                                        <p className="text-xl text-darkgreen">Your Map is Empty</p>
                                        <p className="text-lg text-litegreen mt-2">Adding your Address</p>

                                        <p className="text-xs text-white mt-2">1] On the left hand side of the screen navigate to the Account Page</p>
                                        <p className="text-xs text-white mt-1">2] Once in the Account Page enter your address into the field under settings</p>
                                        <p className="text-xs text-white mt-1">3] Make sure your address is properly formatted</p>
                                        <p className="text-xs text-white mt-1">4] Click on the [Save Preferences] button and that will save your address to your account</p>
                                        <p className="text-xs text-white mt-1">5] Once your address is saved you may navigate back to this page to see your map</p>

                                        <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                        <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                    </div>
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty

                                <div
                                    className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} w-full h-full p-1.5 rounded-md shadow-gray-900 animate-fadeIn`}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{key: 'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E'}}
                                        center={mapCenter}
                                        defaultZoom={mapZoom}
                                        options={mapOptions}
                                    >
                                    </GoogleMapReact>

                                    {/*<gmp-map*/}
                                    {/*    center="37.4220656,-122.0840897"*/}
                                    {/*    zoom="10"*/}
                                    {/*    map-id="cdc06aaed34ef1ee"*/}
                                    {/*>*/}
                                    {/*    <gmp-advanced-marker*/}
                                    {/*        position="37.4220656,-122.0840897"*/}
                                    {/*        title="Mountain View, CA"*/}
                                    {/*    ></gmp-advanced-marker>*/}
                                    {/*    <gmp-advanced-marker*/}
                                    {/*        position="47.648994,-122.3503845"*/}
                                    {/*        title="Seattle, WA"*/}
                                    {/*    ></gmp-advanced-marker>*/}
                                    {/*</gmp-map>*/}


                                </div>

                            )}

                        </div>


                        <div
                            className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-bgcolorGreen drop-shadow"} justify-center flex flex-col h-full w-1/5 items-center rounded-md shadow-gray-900`}>
                            <div className="text-litegreen w-full text-xl h-1/8 text-center">Area Data</div>

                            <div className="h-7/8 overflow-y-auto max-h-[calc(100vh-17rem)] scrollbar-none">

                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={greensImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Greens</div>
                                        </div>
                                        <div className="text-white text-sm">{greens} acres</div>
                                    </div>
                                </div>


                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={surroundsImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Surrounds</div>
                                        </div>
                                        <div className="text-white text-sm">{surrounds} acres</div>
                                    </div>
                                </div>

                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={collarsImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Collars</div>
                                        </div>
                                        <div className="text-white text-sm">{collars} acres</div>
                                    </div>
                                </div>

                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={fairwaysImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Fairways</div>
                                        </div>
                                        <div className="text-white text-sm">{fairways} acres</div>
                                    </div>
                                </div>

                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={roughImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Rough</div>
                                        </div>
                                        <div className="text-white text-sm">{rough} acres</div>
                                    </div>
                                </div>


                                <div className="p-3 flex w-full items-center rounded-md">
                                    <div className="flex items-center w-full">
                                        <div className="mr-2 rounded py-2 flex flex-col items-center justify-center">
                                            <img src={teesImg} alt="" className="w-10"/>
                                            <div className="text-litegreen text-xs mt-1">Tees</div>
                                        </div>
                                        <div className="text-white text-sm">{tees} acres</div>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;



//AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E
