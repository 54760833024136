import {useDarkMode} from "../../contexts/darkModeContext";
import React from "react";

const NutrientCard = ({ nutrient , tankNum}) => {

    const { darkMode } = useDarkMode();

    const handleDivision = (value) => {
        if (value < 0.1) {
            const ozValue = (value * 16).toFixed(2);
            return ozValue + " oz";
        } else {
            return value.toFixed(2) + " lbs";
        }
    };

    return (
        <div className={`${darkMode ? " hover:opacity-80" : " drop-shadow hover:opacity-80"} flex items-center justify-center rounded-md p-1 m-2 shadow-gray-900 mt-2`}>

            {/*Section 1*/}
            <div className={`${darkMode ? "shadow-inner shadow-gray-900" : "shadow-sm drop-shadow shadow-gray-300"} flex flex-col items-center justify-center rounded-md border p-1 border-solid border-green-600 bg-green-600/15 mr-5 w-full`}>
                <div className={`${darkMode ? "text-white" : "text-black"} text-md line-clamp-1`}>{nutrient.name}</div>
            </div>

            {/*Section 2*/}
            <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                <div className="text-sm text-darkgreen line-clamp-1 mr-5">Tank:</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{handleDivision(nutrient.totalValue / tankNum)}</div>
            </div>

            {/*Section 3*/}
            <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex items-center justify-center shadow-gray-900 p-2 w-full`}>
                <div className="text-sm text-litegreen line-clamp-1 mr-5">Total:</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{handleDivision(nutrient.totalValue)}</div>
            </div>

        </div>
    );
};

export default NutrientCard;
