import React, { useState, useEffect } from 'react';
import Dashboard from "../Dashboard";
import {useAuth} from "../../contexts/authContext";
import {db} from "../../firebase/firebase";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {Pie, Bar, Doughnut} from 'react-chartjs-2';
import {useDarkMode} from "../../contexts/darkModeContext";

Chart.register(ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);

export const chemOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Chemical Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Volume [Gal]',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

export const priceOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
            type: 'category',
            title: {
                display: true,
                text: 'Chemical Name',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Unit Price',
                color: 'white',
                font: {
                    size: 16,
                },
            },
        },
    },
};

const ChemsDash = () => {

    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    // eslint-disable-next-line
    const [chems, setChems] = useState([]);
    const [classificationCounts, setClassificationCounts] = useState({});
    const [nutrientCounts, setNutrientCounts] = useState({});
    const [consistCounts, setConsistCounts] = useState({});
    const [manufacturerCounts, setManufacturerCounts] = useState({});
    const [chemsBarChartData, setChemsBarChartData] = useState(null);
    const [priceBarChartData, setPriceBarChartData] = useState(null);



    useEffect(() => {
        const fetchChems = async () => {
            if (currentUser) {
                try {
                    const chemsCollectionRef = db.collection('Users').doc(currentUser.uid).collection('chems');
                    const snapshot = await chemsCollectionRef.get();

                    if (!snapshot.empty) {
                        const chemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setChems(chemsData);

                        const barChartData = {
                            labels: chemsData.map(chem => chem.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Total Volume [Gal]',
                                    backgroundColor: 'rgba(75, 205, 129, 0.2)',
                                    borderColor: 'rgba(75, 205, 129, 0.8)',
                                    borderWidth: 2,
                                    data: chemsData.map(chem => calculateTotalVolume(chem)) // Calculating total price for each document
                                }
                            ]
                        };
                        setChemsBarChartData(barChartData);

                        const priceBarChartData = {
                            labels: chemsData.map(chem => chem.name), // Using document IDs as labels
                            datasets: [
                                {
                                    label: 'Unit Price',
                                    backgroundColor: 'rgba(122, 197, 158, 0.2)',
                                    borderColor: 'rgba(122, 197, 159, 0.8)',
                                    borderWidth: 2,
                                    data: chemsData.map(chem => chem.unitprice) // Calculating total price for each document
                                }
                            ]
                        };
                        setPriceBarChartData(priceBarChartData);

                        const counts = {};
                        chemsData.forEach(doc => {
                            const classification = doc.classification;
                            counts[classification] = (counts[classification] || 0) + 1;
                        });
                        setClassificationCounts(counts);

                        const counts2 = {};
                        chemsData.forEach(doc => {
                            const nutrients = doc.nutrients;
                            nutrients.forEach(nutrient => {
                                const name = nutrient.name;
                                counts2[name] = (counts2[name] || 0) + 1;
                            });
                        });
                        setNutrientCounts(counts2);

                        const counts3 = {};
                        chemsData.forEach(doc => {
                            const consist = doc.type;
                            counts3[consist] = (counts3[consist] || 0) + 1;
                        });
                        setConsistCounts(counts3);

                        const counts4 = {};
                        chemsData.forEach(doc => {
                            const manu = doc.manufacturer;
                            counts4[manu] = (counts4[manu] || 0) + 1;
                        });
                        setManufacturerCounts(counts4);


                    } else {
                        console.log('No chems found');
                    }
                } catch (error) {
                    console.error('Error fetching chems:', error);
                }
            }
        };
        fetchChems();
    }, [currentUser]);


    const chemChart = {
        labels: Object.keys(classificationCounts),
        datasets: [
            {
                label: '# of Chems',
                data: Object.values(classificationCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart2 = {
        labels: Object.keys(nutrientCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(nutrientCounts),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)' // Similar to '#293651' but brighter
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)' // Similar to '#293651' but brighter
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart3 = {
        labels: Object.keys(consistCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(consistCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };

    const chemChart4 = {
        labels: Object.keys(manufacturerCounts),
        datasets: [
            {
                label: '# of Instances',
                data: Object.values(manufacturerCounts),
                backgroundColor: [
                    'rgba(100, 140, 180, 0.2)', // Lighter blue
                    'rgba(70, 220, 130, 0.2)', // Bright green
                    'rgba(190, 225, 150, 0.2)', // Light lime green
                    'rgba(140, 100, 200, 0.2)', // Lavender
                    'rgba(220, 160, 160, 0.2)', // Light coral
                    'rgba(60, 100, 170, 0.2)', // Lighter navy blue
                    'rgba(110, 160, 200, 0.2)' // Lighter sky blue
                ],
                borderColor: [
                    'rgba(100, 140, 180, 1)', // Lighter blue
                    'rgba(70, 220, 130, 1)', // Bright green
                    'rgba(190, 225, 150, 1)', // Light lime green
                    'rgba(140, 100, 200, 1)', // Lavender
                    'rgba(220, 160, 160, 1)', // Light coral
                    'rgba(60, 100, 170, 1)', // Lighter navy blue
                    'rgba(110, 160, 200, 1)' // Lighter sky blue
                ],


                borderWidth: 1,
            },
        ],
    };


    const calculateTotalVolume = (chem) => {
        return ((chem.quantity * chem.volume) + chem.excess);
    };

    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-full w-full mt-16`}>

            <Dashboard/>


            <div className="flex-col w-full rounded-lg mr-3 mt-3 mb-3 overflow-y-auto max-h-[calc(100vh-5.5rem)] scrollbar-none">

                <div className="justify-center flex flex-row w-flex h-1/2 items-center ">



                    <div className="w-1/2 h-full mr-3 justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Volume Per Chemical</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full">
                                {chems.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {chemsBarChartData && <Bar options={chemOptions} data={chemsBarChartData} className="p-2"/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Chemical Classifications</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={chemChart} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center">
                        <div className="text-litegreen w-full text-center mb-1">Nutrient MakeUp</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={chemChart2} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                </div>





                <div className="justify-center flex flex-row w-flex h-1/2 items-center">


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Chemical Consistencies</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Pie data={chemChart3} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>


                    <div className="w-1/4 max-w-screen-md h-full justify-center items-center mr-3">
                        <div className="text-litegreen w-full text-center mb-1">Manufacturer MakeUp</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full items-center justify-center flex">
                                <Doughnut data={chemChart4} className="mr-auto ml-auto p-2"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 h-full">
                        <div className="text-litegreen w-full text-center mb-1">Unit Price Per Chemical</div>
                        <div className={`flex justify-center rounded-xl ${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} shadow-gray-800`}>
                            <div className="w-full max-w-screen-md h-full">
                                {chems.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex items-center justify-center h-full">
                                        <div
                                            className="bg-bgcolor p-4 rounded-xl shadow-lg shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-darkgreen">Your Database is Empty</p>
                                            <p className="text-lg text-litegreen mt-2">Add Chemicals into your
                                                database!</p>
                                            <p className="text-xs text-center text-white mt-2">In order to see data
                                                displayed in these charts you must first add data to your
                                                database!</p>
                                        </div>
                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    <div className="w-full h-full items-center justify-center flex">
                                        {chemsBarChartData && <Bar options={priceOptions} data={priceBarChartData} className="p-2"/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>


            </div>


        </div>
    );
};


export default ChemsDash;
