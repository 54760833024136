import {useDarkMode} from "../../contexts/darkModeContext";
import React from "react";

const IngredientCard = ({ingredient, area, areaUnit, tankNum}) => {

    let areaNum;

    if (areaUnit === "acres") {
        areaNum = area * 43560; // Convert acres to square feet
    } else {
        areaNum = area; // Assume the area is already in square feet
    }

    const { darkMode } = useDarkMode();

    const rate = ingredient.rate;
    const containerVol = ingredient.containerVol;

    const volumeOZ = (areaNum * (rate / 1000)).toFixed(2);
    const volumeGal = (volumeOZ / 128).toFixed(2)

    const totprice = (volumeGal * ingredient.price).toFixed(2);

    const tankCont = ((volumeGal / tankNum) / containerVol).toFixed(2);
    const totalCont = (volumeGal / containerVol).toFixed(2);

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={`${darkMode ? " hover:opacity-80" : " drop-shadow hover:opacity-80"} flex items-center justify-center rounded-md p-1 m-2 shadow-gray-900 mt-2`}>

            {/*Section 1*/}
            <div className="flex flex-col items-center justify-center shadow-d shadow-gray-900 rounded-md border-2 p-1 border-solid border-green-600 bg-green-600/15 mr-5 w-full">
                <div className="text-sm text-darkgreen line-clamp-1">{ingredient.name}</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{rate + " oz / 1000 sqft"}</div>
            </div>

            {/*Section 2*/}
            <div className="flex flex-col items-center justify-center mr-5 w-full space-y-2">
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Volume</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{numberWithCommas((volumeGal / tankNum).toFixed(2)) + " gal"} | {numberWithCommas((volumeOZ / tankNum).toFixed(2)) + " oz"}</div>
                </div>
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-litegreen line-clamp-1">Total Volume</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{numberWithCommas(volumeGal) + " gal"} | {numberWithCommas(volumeOZ) + " oz"}</div>
                </div>
            </div>

            {/*Section 3*/}
            <div className="flex flex-col items-center justify-center mr-5 w-full space-y-2">
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Price</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>${numberWithCommas((totprice / tankNum).toFixed(2))}</div>
                </div>
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-litegreen line-clamp-1">Total Price</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>${numberWithCommas(totprice)}</div>
                </div>
            </div>

            {/*Section 4*/}
            <div className="flex flex-col items-center justify-center mr-5 w-full space-y-2">
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-darkgreen line-clamp-1">Tank Containers</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{tankCont}</div>
                </div>
                <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-1 w-full`}>
                    <div className="text-sm text-litegreen line-clamp-1">Total Containers</div>
                    <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{totalCont}</div>
                </div>
            </div>

        </div>
    );
};

export default IngredientCard;
