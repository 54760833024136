import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import React, {useState} from "react";
import {db} from "../../firebase/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {TrashIcon} from "@heroicons/react/24/outline";
import {Button} from "@headlessui/react";

const ServiceCard = ({service, unit, id, machineId}) => {

    const {currentUser} = useAuth();

    const { darkMode } = useDarkMode();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = async () => {
        try {
            const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId);
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service: currentServices } = doc.data();
                const updatedServices = currentServices.filter((_, currentIndex) => currentIndex !== id);
                await fleetRef.update({ service: updatedServices });
                toggleDeleteConfirmation()
            }
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editedService, setEditedService] = useState(service);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        try {
            const fleetRef = db.collection('Users').doc(currentUser.uid).collection('fleet').doc(machineId);
            const doc = await fleetRef.get();
            if (doc.exists) {
                const { service: currentServices } = doc.data();
                // Update the specific service item in the array
                currentServices[id] = editedService;
                // Update the entire service array in Firestore
                await fleetRef.update({ service: currentServices });

                // If the edited item is the last one in the array
                if (id === currentServices.length - 1) {
                    // Update 'usage' field with the edited service usage
                    await fleetRef.update({ 'usage': editedService.serviceUsage });
                }

                toggleEditMode(); // Exit edit mode after saving
            }
        } catch (error) {
            console.error('Error updating service:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedService({
            ...editedService,
            [name]: value
        });
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={`${darkMode ? " hover:opacity-80" : " drop-shadow hover:opacity-80"} flex items-center justify-center rounded-md p-1 m-2 shadow-gray-900 mt-2`}>

            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} p-4 rounded-md`}>
                        <p className={`${darkMode ? "text-white" : "text-black"}`}>Are you sure you want to delete this Service?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-md bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-md bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditing ? (
                <div className="absolute top-0 left-0 w-full h-full bg-black z-50 bg-opacity-50 flex items-center justify-center animate-fadeInFast">

                    <div className="bg-bgcolor p-4 rounded-lg flex flex-col">

                        <div className="text-sm text-litegreen font-bold text-center mb-2">Edit Service
                            For: {service.serviceDate}</div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceDate">
                                Date:
                            </label>

                            <input
                                type="text"
                                name="serviceDate"
                                value={editedService.serviceDate}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceProvider">
                                Provider:
                            </label>

                            <input
                                type="text"
                                name="serviceProvider"
                                value={editedService.serviceProvider}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-start mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceType">
                                Type:
                            </label>

                            <select
                                id="serviceType"
                                name="serviceType"
                                value={editedService.serviceType}
                                onChange={handleChange}
                                className="bg-bglite w-full appearance-none rounded-md py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                            >
                                <option value="">Select...</option>

                                <option value="Oil change">Oil change</option>
                                <option value="Filter replacement (air, oil, fuel)">Filter replacement (air, oil, fuel)</option>
                                <option value="Blade sharpening">Blade sharpening</option>
                                <option value="Belt replacement">Belt replacement</option>
                                <option value="Tire replacement">Tire replacement</option>
                                <option value="Battery check/replacement">Battery check/replacement</option>
                                <option value="Electrical system inspection/repair">Electrical system inspection/repair</option>
                                <option value="Brake inspection/adjustment">Brake inspection/adjustment</option>
                                <option value="Carburetor cleaning/adjustment">Carburetor cleaning/adjustment</option>
                                <option value="Hydraulic fluid change">Hydraulic fluid change</option>
                                <option value="Fuel system cleaning">Fuel system cleaning</option>
                                <option value="Engine tune-up">Engine tune-up</option>
                                <option value="Lubrication of moving parts">Lubrication of moving parts</option>
                                <option value="Ignition system check/repair">Ignition system check/repair</option>
                                <option value="Cooling system flush">Cooling system flush</option>
                                <option value="Clutch adjustment/replacement">Clutch adjustment/replacement</option>
                                <option value="Steering system inspection/alignment">Steering system inspection/alignment</option>
                                <option value="Transmission service">Transmission service</option>
                                <option value="Mower deck leveling">Mower deck leveling</option>
                                <option value="General inspection and maintenance">General inspection and maintenance</option>

                            </select>
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceUsage">
                                Usage:
                            </label>

                            <input
                                type="number"
                                name="serviceUsage"
                                value={editedService.serviceUsage}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex items-center justify-center mt-2 mb-2">
                            <label className="block text-white text-sm mr-5" htmlFor="serviceCost">
                                Cost:
                            </label>

                            <input
                                type="number"
                                name="serviceCost"
                                value={editedService.serviceCost}
                                onChange={handleChange}
                                className="bg-bgcard appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-inner shadow-gray-900"
                                required
                            />
                        </div>

                        <div className="flex">
                            <button type="submit" className="w-1/2 rounded-lg bg-darkgreen text-white p-1 mr-2" onClick={handleSave}>Update</button>
                            <button type="submit" className="w-1/2 rounded-lg bg-red-700 text-white p-1 ml-2" onClick={toggleEditMode}>Cancel</button>
                        </div>


                    </div>
                </div>
            ) : null}

            <div className="mr-2 items-center justify-center">
                <Button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                    <TrashIcon className="w-5 text-red-700 hover:text-red-500 hover:animate-pulse"/>
                </Button>
            </div>

            <div className={`flex flex-col items-center justify-center shadow-gray-900 rounded-md border-2 p-1 border-solid border-green-600 bg-green-600/15 mr-5 w-full`}>
                <div className="text-sm text-white line-clamp-1">{service.serviceDate}</div>
                <div className="flex items-center justify-center w-full">
                    <div className={`${darkMode ? "text-darkgreen" : "text-black"} text-sm line-clamp-1 mr-1`}>Provider:</div>
                    <div className="text-sm text-white line-clamp-1">{service.serviceProvider}</div>
                </div>

            </div>

            <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} flex flex-col items-center justify-center shadow-gray-900 p-1 rounded-md mr-5 w-full`}>
                <div className="text-sm text-darkgreen line-clamp-1">Service Type:</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{service.serviceType}</div>
            </div>

            <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} flex flex-col items-center justify-center shadow-gray-900 p-1 rounded-md mr-5 w-1/3`}>
                <div className="text-sm text-darkgreen line-clamp-1">Usage:</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>{numberWithCommas(service.serviceUsage) + " " + unit}</div>
            </div>

            <div className={`${darkMode ? "bg-bgcolor shadow-inner" : "bg-white drop-shadow"} flex flex-col items-center justify-center shadow-gray-900 p-1 rounded-md mr-5 w-1/4`}>
                <div className="text-sm text-darkgreen line-clamp-1">Cost:</div>
                <div className={`${darkMode ? "text-white" : "text-black"} text-sm line-clamp-1`}>${numberWithCommas(service.serviceCost)}</div>
            </div>

            <div className="mr-2 items-center justify-center">
                <button className={`${darkMode ? "text-white" : "text-gray-400"} text-xl font-bold hover:text-litegreen`} onClick={toggleEditMode}>
                    <FontAwesomeIcon icon={faEdit}/>
                </button>
            </div>

        </div>
    );
};

export default ServiceCard;
