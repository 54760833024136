import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    AdjustmentsHorizontalIcon,
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    ChevronDownIcon, DocumentDuplicateIcon, EllipsisHorizontalCircleIcon, PencilSquareIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {Pie} from "react-chartjs-2";
import NutrientCard from "./NutrientCard";
import {Button, Popover, PopoverButton, PopoverPanel} from "@headlessui/react";

const ChemCard = ({chem, onDelete, onEdit}) => {
    const [expanded, setExpanded] = useState(false); // State to track expansion
    const volume = ((chem.quantity * chem.volume) + chem.excess).toFixed(2);
    const price = (volume * chem.unitprice).toFixed(2);
    const adjustedExcess = (chem.excess * 1).toFixed(2);
    const adjustedVolume = (chem.volume * 1).toFixed(2);
    const adjustedPrice = (chem.unitprice * 1).toFixed(2);

    const rate = chem.rate;
    const nutrients = chem.nutrients;

    const { darkMode } = useDarkMode();

    const Rate = (rate * 1).toFixed(2);
    const galRate = (((rate / 128) / 1000) * 43560).toFixed(2);
    const mlRate = (((rate * 29.5735) / 1000) * 1076.39).toFixed(2);
    const lRate = (((rate / 33.814) / 1000) * 107639).toFixed(2);

    const Weight = (chem.weight * 1).toFixed(2);
    const containerWeight = (chem.weight * chem.volume).toFixed(2);
    const totalWeight = (volume * chem.weight).toFixed(2);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        onDelete(chem.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const nutrientChart = {
        labels: nutrients.map(nutrient => nutrient.name),
        datasets: [
            {
                label: '%',
                data: nutrients.map(nutrient => nutrient.percentage),
                backgroundColor: [
                    'rgba(95, 135, 155, 0.2)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 0.2)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 0.2)', // Light turquoise
                    'rgba(120, 145, 190, 0.2)', // Light indigo
                    'rgba(200, 160, 160, 0.2)', // Light pink
                    'rgba(90, 110, 150, 0.2)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 0.2)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderColor: [
                    'rgba(95, 135, 155, 1)', // Similar to '#1D263A' but brighter
                    'rgba(75, 205, 129, 1)', // Similar to '#147E1F' but brighter
                    'rgba(140, 205, 200, 1)', // Light turquoise
                    'rgba(120, 145, 190, 1)', // Light indigo
                    'rgba(200, 160, 160, 1)', // Light pink
                    'rgba(90, 110, 150, 1)', // Similar to '#1E222D' but brighter
                    'rgba(85, 145, 180, 1)', // Similar to '#293651' but brighter
                    // Add more colors as needed
                ],
                borderWidth: 1,
            },
        ],
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }




    const toggleExpansion = () => {
        setExpanded(!expanded); // Toggle expansion state
    };

    const totalContainerValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * chem.volume * chem.weight);
    }, 0).toFixed(2);

    const totalNutrientValue = chem.nutrients.reduce((accumulator, current) => {
        return accumulator + ((current.percentage / 100) * volume * chem.weight);
    }, 0).toFixed(2);

    return (
        <div className={`${darkMode ? "bg-bgDark  " : "bg-white drop-shadow"} p-2 rounded-lg items-center justify-center shadow-gray-900 mb-2 w-full animate-cascade`}>

            {/* Deletion confirmation popup */}
            {showDeleteConfirmation && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} p-4 rounded-lg`}>
                        <p className={`${darkMode ? "text-white" : "text-black"}`}>Are you sure you want to delete this Chemical?</p>
                        <div className="flex mt-5">
                            <button className="w-full rounded-lg bg-red-700 text-white p-2 mr-5" onClick={handleDelete}>Yes</button>
                            <button className="w-full rounded-lg bg-darkgreen text-white p-2" onClick={toggleDeleteConfirmation}>No</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-center hover:opacity-80">

                <div className="mr-2">
                    <Button className="text-xl text-white font-bold" onClick={toggleDeleteConfirmation}>
                        <TrashIcon className="w-5 text-red-700 hover:text-red-500 hover:animate-pulse"/>
                    </Button>
                </div>


                {/*Section 1*/}
                <div className={`${darkMode ? " " : "drop-shadow"} bg-green-600/15 border-2 border-green-600 h-full rounded-lg flex flex-col items-center justify-center p-2 w-full shadow-gray-900 mr-5`}>
                    <div className="text-lg text-center line-clamp-1">{chem.name}</div>
                    <div className="text-xs text-gray-500 text-center line-clamp-1">{chem.manufacturer}</div>
                </div>


                {/*Section 2*/}
                <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex p-2 w-full shadow-gray-50 mr-5`}>

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Containers:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Volume Per:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>Excess:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className={`rounded-lg border border-1 p-1.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.quantity}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{adjustedVolume + " " + chem.unit}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>{adjustedExcess + " " + chem.unit}</div>
                        </div>

                    </div>

                </div>


                {/*Section 3*/}
                <div className={`${darkMode ? "bg-bgcolor" : "bg-bgcolorLight drop-shadow border border-neutral-300"} h-full rounded-lg flex p-2 w-full shadow-gray-900 mr-5`}>

                    <div className="flex flex-col items-start p-1 justify-center mr-2 w-full">
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Classification:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>Consistency:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>Unit Price:</div>
                    </div>

                    <div className="flex flex-col items-end p-1 justify-center w-full">

                        <div className={`rounded-lg border border-1 p-1.5 drop-shadow-xl border-neutral-500 ${darkMode ? '' : 'bg-bgcolorLight'}`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.classification}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center mb-1 line-clamp-1`}>{chem.type}</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center line-clamp-1`}>${adjustedPrice}</div>
                        </div>

                    </div>

                </div>


                {/*Section 4*/}
                <div className="h-full rounded-lg flex items-center justify-center w-full">

                    <div className="flex flex-col items-center justify-center w-full">

                        <div className="text-lg text-darkgreen text-center line-clamp-1">Total Volume:</div>
                        <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center mb-2 line-clamp-1`}>{numberWithCommas(volume) + " " + chem.unit}</div>

                        <div className={`${darkMode ? " " : ""} rounded-lg border-2 px-4 py-0.5 border-solid border-green-600 bg-green-600/15 shadow-gray-900`}>
                            <div className="text-lg text-darkgreen text-center line-clamp-1">Total Price:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-md text-center line-clamp-1`}>{"$" + numberWithCommas(price)}</div>
                        </div>

                    </div>


                </div>


                {/*Edit and Expand*/}
                <div className="flex flex-col items-center relative">

                    <Popover className="relative mb-8 flex">
                        <PopoverButton className="inline-flex items-center gap-x-1 text-sm/6 font-semibold">
                            <EllipsisHorizontalCircleIcon aria-hidden="true" className={`size-6 ${darkMode ? "text-white" : "text-gray-400"}`} />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute z-10 pr-56 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className={`w-48 flex flex-col space-y-3 py-3 shrink rounded-xl border ${darkMode ? 'bg-bgcolor drop-shadow-2xl border-neutral-700' : 'bg-white'} p-2 text-sm/6 font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5`}>

                                <Button
                                    onClick={() => onEdit(chem)}
                                    className={`flex text-sm items-center ${darkMode ? "text-white" : "text-gray-400"} px-2 hover:text-opacity-50`}
                                >
                                    <PencilSquareIcon className={`w-5 mr-2`}/>
                                    Edit Chem
                                </Button>

                                <Button
                                    onClick={() => onEdit(chem)}
                                    className={`flex text-sm items-center ${darkMode ? "text-white" : "text-gray-400"} px-2 hover:text-opacity-50`}
                                >
                                    <DocumentDuplicateIcon className={`w-5 mr-2`}/>
                                    Duplicate Chem
                                </Button>

                            </div>
                        </PopoverPanel>
                    </Popover>

                    <Button className={`${darkMode ? "text-white" : "text-gray-400"} text-xxl font-bold mt-auto mb-auto hover:text-litegreen hover:animate-pulse`}
                            onClick={toggleExpansion}>
                        {expanded ?
                            <ArrowUpCircleIcon className={`w-6`}/>
                            :
                            <ArrowDownCircleIcon className={`w-6`}/>
                        }
                    </Button>

                </div>


            </div>


            {/* Conditionally render content based on expansion state */}
            {expanded && (
                <div className={`p-2`}>

                    <div className={`${darkMode ? "text-white" : "text-black"} text-md font-bold text-center mt-5 border-b-4 ${darkMode ? 'border-neutral-700' : ''} mx-6 pb-1 border-dotted`}>APPLICATION RATE</div>


                    {/*App rates*/}
                    <div className={`${darkMode ? "bg-bgDark border-neutral-700 border-2" : "border-1"} border h-full rounded-lg flex p-2 w-full shadow-gray-900 mt-5`}>

                        <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>Fluid oz / 1000 sq ft.:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{Rate + " oz"}</div>
                        </div>

                        <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>Gal / 1 acre:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{galRate + " gal"}</div>
                        </div>

                        <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>ML / 100 sq. m:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{mlRate + " ml"}</div>
                        </div>

                        <div className={`${darkMode ? "bg-bgcolor" : "bg-white drop-shadow border border-neutral-300"} h-full rounded-lg flex flex-col items-center justify-center shadow-gray-900 p-2 w-full mr-5`}>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs font-bold text-center mb-1 line-clamp-1`}>L / HA:</div>
                            <div className={`${darkMode ? "text-white" : "text-black"} text-xs text-center rounded-lg border-2 py-0.5 px-1 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{lRate + " L"}</div>
                        </div>

                    </div>


                    <div className={`${darkMode ? "text-white border-neutral-700" : "text-black"} text-md font-bold text-center mt-5  border-b-4 mx-6 pb-1 border-dotted`}>NUTRIENT DATA</div>


                    {/*Nutrient*/}
                    <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white border drop-shadow"} flex items-center justify-center rounded-lg p-2 shadow-gray-900 mt-5 mr-24 ml-24`}>

                        <div className="mr-auto ml-auto">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Weight per gal:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{Weight + " " + chem.unitweight}</div>
                            </div>
                        </div>

                        <div className="mr-auto">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Container Weight:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{containerWeight + " " + chem.unitweight}</div>
                            </div>
                        </div>

                        <div className="mr-auto">
                            <div className="flex items-center justify-center">
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm mr-2 line-clamp-1`}>Total Weight:</div>
                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{numberWithCommas(totalWeight) + " " + chem.unitweight}</div>
                            </div>
                        </div>

                    </div>


                    <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white border drop-shadow"} h-full rounded-lg  p-3 w-full shadow-gray-900 mt-5`}>


                        {/*Nutrients Map*/}
                        <div className="w-full flex flex-row">

                            <div className={`w-2/3 rounded-lg ${darkMode ? 'border-neutral-700' : ''}`}>

                                <div className="items-center justify-center rounded-lg overflow-y-auto max-h-[calc(100vh-28rem)] scrollbar-none">

                                    {chem.nutrients.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex items-center justify-center">
                                            <div className={`${darkMode ? "bg-bgcolor  " : "bg-white drop-shadow"} p-4 rounded-lg shadow-gray-900 flex items-center justify-center flex-col m-5`}>
                                                <p className="text-xl text-darkgreen">This Chem Has No Nutrients</p>
                                                <p className="text-lg text-litegreen mt-1">Adding a Nutrient</p>

                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-2`}>1] Press the Edit Button to the right of the Chem Card</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>2] This will fill the panel to the right with your chem's data</p>
                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>3] Navigate to the Nutrients Section and add in your nutrients and then press Update Chem</p>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        chem.nutrients.map((nutrient, index) => (
                                            <NutrientCard
                                                key={index}
                                                nutrient={nutrient}
                                                Vol={volume}
                                                Weight={chem.weight}
                                                galVol={chem.volume}
                                                index={index}
                                            />
                                        ))
                                    )}

                                </div>

                            </div>

                            <div className="w-1/3 max-w-screen-md h-full">

                                <div className={`${darkMode ? "text-white" : "text-black"} text-sm font-bold mr-2 text-center line-clamp-1`}>NUTRIENT MAKEUP:</div>

                                <div className="flex justify-center items-center">
                                    <Pie data={nutrientChart} className="mr-auto ml-auto"/>
                                </div>

                            </div>

                        </div>

                        {/*Nutrients Data*/}
                        <div className={`${darkMode ? "bg-bgDark border-2 border-neutral-700" : "bg-white drop-shadow border-1 border"} flex items-center justify-center rounded-lg p-1 shadow-gray-900 mt-2.5 w-full`}>

                            <div className="mr-auto ml-auto">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm mr-2 line-clamp-1`}>Nutrients:</div>
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{chem.nutrients.length}</div>
                                </div>
                            </div>

                            <div className="mr-auto">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm mr-2 line-clamp-1`}>Container Nutrients:</div>
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{totalContainerValue + " lbs"}</div>
                                </div>
                            </div>

                            <div className="mr-auto">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm mr-2 line-clamp-1`}>Total Nutrients:</div>
                                    <div className={`${darkMode ? "text-white" : "text-black" } text-sm rounded-lg border-2 px-1 py-0.5 border-solid border-green-600 bg-green-600/15 line-clamp-1`}>{numberWithCommas(totalNutrientValue) + " lbs"}</div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            )}

        </div>
    );
};

export default ChemCard;
