import React, {useEffect, useRef, useState} from 'react';
import Dashboard from '../../components/Dashboard';
import { useAuth } from "../../contexts/authContext";
import { db } from "../../firebase/firebase";
import axios from 'axios';
import {Polyline} from "../polyline";
import {AdvancedMarker, APIProvider, Map, ControlPosition, MapControl, Pin, useAdvancedMarkerRef} from '@vis.gl/react-google-maps';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretDown, faEdit,
    faSquareCaretLeft, faSquareCaretRight, faTrash,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {useDarkMode} from "../../contexts/darkModeContext";
import {Polygon} from "../polygon";
import {MapPinIcon, PencilIcon} from "@heroicons/react/24/outline";
import {UndoRedoControl} from "../undoRedoControl";
import {useDrawingManager} from "../useDrawingManager";
import {DrawingManager, DrawingManagerF, GoogleMap, LoadScript} from "@react-google-maps/api";

const MapView = () => {
    const { currentUser } = useAuth();
    const [address, setAddress] = useState('');
    const [customLocations, setCustomLocations] = useState([]);
    const [mapClickCoords, setMapClickCoords] = useState([]);
    const [locationName, setLocationName] = useState('');
    const [locationType, setLocationType] = useState('');
    const [mapZoom, setMapZoom] = useState(16);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [infoWindowData, setInfoWindowData] = useState(null);
    const [originalLocations, setOriginalLocations] = useState([]);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
    const [loading, setLoading] = useState(true);

    const [mapMode, setMapMode] = useState(false);

    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(null);

    const [length, setLength] = useState(0);
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [duration, setDuration] = useState("");

    const [isChecked, setIsChecked] = useState({
        Green: true,
        Tee: true,
        Fairway: true,
        Structure: true,
        Irrigation: true,
        Bunker: true,
    });

    const { darkMode } = useDarkMode();

    // eslint-disable-next-line
    const [markerRef, marker] = useAdvancedMarkerRef();

    const handleMarkerClick = (location) => {
        setInfoWindowData(location);
    };

    const handleCloseWindow = () => {
        setInfoWindowData(null);
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;

        if (id === "selectAll") {
            setIsSelectAllChecked(checked);
            setIsChecked({
                Green: checked,
                Tee: checked,
                Fairway: checked,
                Structure: checked,
                Irrigation: checked,
                Bunker: checked,
            });
        } else {
            setIsChecked(prevState => ({ ...prevState, [id]: checked }));

            if (!checked) {
                setIsSelectAllChecked(false);
            } else {
                const allChecked = Object.keys(isChecked).every(key => key === id || isChecked[key]);
                if (allChecked) {
                    setIsSelectAllChecked(true);
                }
            }
        }
    };

    const filterData = () => {
        if (isSelectAllChecked) {
            setCustomLocations(originalLocations);
            return;
        }

        const filtered = originalLocations.filter(item => {
            if (item.type === 'Green' && isChecked.Green) return true;
            if (item.type === 'Tee' && isChecked.Tee) return true;
            if (item.type === 'Fairway' && isChecked.Fairway) return true;
            if (item.type === 'Structure' && isChecked.Structure) return true;
            if (item.type === 'Irrigation' && isChecked.Irrigation) return true;
            if (item.type === 'Bunker' && isChecked.Bunker) return true;
            return false;
        });
        setCustomLocations(filtered);
    };

    useEffect(() => {
        filterData();
        // eslint-disable-next-line
    }, [isChecked, isSelectAllChecked]);

    useEffect(() => {
        if (selectedRoute && selectedRoute.path && selectedRoute.path.length > 0) {
            const totalLength = calculatePolylineLength(selectedRoute.path);
            setLength(totalLength);
            setDate(selectedRoute.date);
            setStartTime((selectedRoute.startTime));
            setEndTime((selectedRoute.endTime));
            setDuration(selectedRoute.duration);
        } else {
            setLength(0);
            setDate("");
            setStartTime("");
            setEndTime("");
            setDuration("");
        }
    }, [selectedRoute]); // Dependency array now includes selectedRoute


    useEffect(() => {
        const fetchCustomLocations = async () => {
            if (currentUser) {
                try {
                    const storedLocations = localStorage.getItem(`customLocations_${currentUser.uid}`);
                    if (storedLocations) {
                        const locationsData = JSON.parse(storedLocations);
                        setCustomLocations(locationsData);
                        setOriginalLocations(locationsData);
                    } else {
                        const locationsSnapshot = await db.collection('Users').doc(currentUser.uid).collection('holes').get();
                        const locationsData = locationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setCustomLocations(locationsData);
                        setOriginalLocations(locationsData);
                        localStorage.setItem(`customLocations_${currentUser.uid}`, JSON.stringify(locationsData));
                        console.log("Fetched Locations:", locationsData);
                    }
                } catch (error) {
                    console.error('Error fetching custom locations:', error);
                }
            }
        };

        const fetchRoutes = async () => {
            try {
                const querySnapshot = await db.collection('Users').doc(currentUser.uid).collection('sprayroutes').get();
                const fetchedRoutes = querySnapshot.docs.map(doc => {
                    const data = doc.data();

                    // Convert Firestore timestamp to Date object
                    const startTime = data.start_time.toDate();
                    const endTime = data.end_time ? data.end_time.toDate() : null; // Check if end_time exists

                    // Extract date and start time in desired formats
                    const date = startTime.toLocaleDateString('en-US'); // mm/dd/yyyy format
                    const startTimeString = startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
                    const endTimeString = endTime ? endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : ''; // Return empty string if endTime is null

                    const durationString = endTime ? calculateDuration(startTime, endTime) : ''; // Return empty string if endTime is null

                    return {
                        id: doc.id,
                        path: data.coordinates.map(coord => ({
                            lat: coord.latitude,
                            lng: coord.longitude,
                        })),
                        date: date,
                        startTime: startTimeString,
                        endTime: endTimeString,
                        duration: durationString,
                    };
                });
                setRoutes(fetchedRoutes);
            } catch (error) {
                console.error('Error fetching routes from Firestore:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRoutes();
        fetchCustomLocations();
    }, [currentUser]);

    // Function to calculate the duration between two Date objects
    const calculateDuration = (start, end) => {
        const diffInMs = end - start; // Difference in milliseconds
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        return `${hours}h ${minutes}m`; // Return duration as "Xh Ym"
    };

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                    params: {
                        address: address,
                        key: 'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E',
                    },
                });
                const { results } = response.data;
                if (results && results.length > 0) {
                    const { lat, lng } = results[0].geometry.location;
                    setMapCenter({ lat: lat, lng: lng });
                } else {
                    console.error('No coordinates found for the address');
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        };

        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setAddress(userData.address || "");
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setAddress(userData.address || "");
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
        if (address) {
            fetchCoordinates();
        }
    }, [currentUser, address]);

    const onUpdate = async () => {
        try {
            const locationsSnapshot = await db.collection('Users').doc(currentUser.uid).collection('holes').get();
            const locationsData = locationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCustomLocations(locationsData);
            localStorage.setItem(`customLocations_${currentUser.uid}`, JSON.stringify(locationsData));
            console.log("Fetched Locations:", locationsData);
        } catch (error) {
            console.error('Error updating location data:', error);
        }
    };

    const addCustomLocation = async () => {
        if (currentUser && mapClickCoords && locationName.trim() !== '') {
            try {
                await db.collection('Users').doc(currentUser.uid).collection('holes').add({
                    latitude: mapClickCoords.lat,
                    longitude: mapClickCoords.lng,
                    name: locationName.trim(),
                    type: locationType,
                });

                setLocationName('');
                setMapClickCoords({ lat: 0, lng: 0 }); // Reset mapClickCoords after adding the location
                await onUpdate();
            } catch (error) {
                console.error('Error adding custom location:', error);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        addCustomLocation();
        handleClose();
    };

    const handleClose = () => {
        setLocationName('');
        setMapClickCoords({lat: 0, lng: 0});
    };

    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const getPinColors = (type) => {
        switch (type) {
            case 'Green':
                return {
                    background: '#147E1F', // Green color
                    borderColor: '#4BB862',
                    glyphColor: '#1B2430'
                };
            case 'Fairway':
                return {
                    background: '#81A43B', // Light green for fairways
                    borderColor: '#5F7F2B',
                    glyphColor: '#1B2430'
                };
            case 'Tee':
                return {
                    background: '#008080', // Default color (Green)
                    borderColor: '#4BB862',
                    glyphColor: '#1B2430'
                };
            case 'Structure':
                return {
                    background: '#4F617A', // Dark blue for structures
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            case 'Irrigation':
                return {
                    background: '#000000',
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            case 'Bunker':
                return {
                    background: '#ffffff',
                    borderColor: '#344259',
                    glyphColor: '#1B2430'
                };
            default:
                return {
                    background: '#FF5733', // Bright orange for tees
                    borderColor: '#E74821',
                    glyphColor: '#1B2430'
                };
        }
    };

    const numberOf = (items, itemType) => {
        return items.filter(item => item.type === itemType).length;
    };

    const handleChangeSlider = (event) => {
        setMapZoom(event.target.valueAsNumber);
    };

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Your existing code...

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const deleteMarkerFromFirestore = async (MarkerID) => {
        try {
            await db.collection('Users').doc(currentUser.uid).collection('holes').doc(MarkerID).delete();
            console.log('Chem item deleted successfully');
            await onUpdate()
        } catch (error) {
            console.error('Error deleting chem item:', error);
        }
    };

    const handleDelete = () => {
        // Call the onDelete function passed from the parent component
        deleteMarkerFromFirestore(infoWindowData.id);
        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);
    };

    const handleRouteClick = (route) => {
        console.log('Selected Route:', route);
        setSelectedRoute(route);
    };

    const handleRouteClear = () => {
        setSelectedRoute(null);
    };

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const R = 3958.8; // Radius of the Earth in miles
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const calculatePolylineLength = (coordinates) => {
        let totalDistance = 0;
        for (let i = 0; i < coordinates.length - 1; i++) {
            const { lat: lat1, lng: lon1 } = coordinates[i];
            const { lat: lat2, lng: lon2 } = coordinates[i + 1];
            totalDistance += haversineDistance(lat1, lon1, lat2, lon2);
        }
        return totalDistance;
    };

    const polygonPaths = [
        [
            { lat: 41.49259594636557, lng: -72.71236615355251 },
            { lat: 41.49215373230547, lng: -72.71446020586188 },
            { lat: 41.49530687215993, lng: -72.71434661254509 },
            { lat: 41.49457764836057, lng: -72.7121491952511 },
        ],
        [
            { lat: 41.490000, lng: -72.710000 },
            { lat: 41.491000, lng: -72.711000 },
            { lat: 41.492000, lng: -72.710500 },
            { lat: 41.490500, lng: -72.709500 },
        ]
    ];

    const polygonRefs = useRef([]); // Reference to the Polygon
    const [areaInAcres, setAreaInAcres] = useState(0);

    // Function to calculate the area of the polygon in acres
    const calculatePolygonAreaInAcres = (path) => {
        const areaInSquareMeters = window.google.maps.geometry.spherical.computeArea(path);
        const areaInAcres = areaInSquareMeters / 4046.85642; // Convert to acres
        return areaInAcres;
    };

    // Use useEffect to calculate the area after the Polygon is mounted
    useEffect(() => {
        if (polygonRefs.current) {
            polygonRefs.current.forEach((path) => {
                if (path) {
                    const area = calculatePolygonAreaInAcres(path);
                    setAreaInAcres(area);
                }
            });
        }
    }, [polygonRefs.current]);

    const handleMapClick = (event) => {
        console.log({
            lat: event.detail.latLng.lat(),
            lng: event.detail.latLng.lng(),
        });
    };

    const saveClick = () => {
        console.log(mapClickCoords);
    }

    const drawingManager = useDrawingManager();

    const [state, setState] = React.useState({
        drawingMode: "polygon",
    });


    return (
        <div className={`${darkMode ? "bg-bgpage" : "bg-bgcolorLight"} flex h-screen w-full mt-16`}>
            <Dashboard />
            <div className="z-20 content w-full flex flex-row mb-6">

                <div className="flex flex-col h-full w-full mr-3 mt-2 mb-3">

                    <div className="w-full flex flex-row mb-1">

                        <div className="w-full flex flex-row items-center">

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Total:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-tees drop-shadow shadow-gray-900`}>{originalLocations.length}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Greens:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-darkgreen drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Green')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Fairways:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-fairways drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Fairway')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Tees:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-bgTeal drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Tee')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Structures:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-structures drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Structure')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Irrigation:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-black drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Irrigation')}</div>
                                </div>
                            </div>

                            <div className="w-full animate-fadeIn">
                                <div className="flex items-center justify-center">
                                    <div className={`${darkMode ? "text-white" : "text-black"} text-md mr-2`}>Bunker:</div>
                                    <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} text-sm rounded-md border-2 p-1 border-solid border-white drop-shadow shadow-gray-900`}>{numberOf(originalLocations, 'Bunker')}</div>
                                </div>
                            </div>

                        </div>

                        <button className={`${darkMode ? "text-white" : "text-gray-400"} ml-2 text-3xl`}
                                onClick={toggleCollapse}>
                            {isCollapsed ? <FontAwesomeIcon icon={faSquareCaretLeft}/> :
                                <FontAwesomeIcon icon={faSquareCaretRight}/>}
                        </button>

                    </div>


                    <div className={`${darkMode ? "shadow-md bg-bgcolor" : "bg-white"} relative justify-center h-full w-full flex flex-row items-center rounded-md shadow-gray-900`}>
                        {address === "" ? (
                            <div className="flex items-center justify-center h-full">
                                <div className="bg-bgcolor py-2 rounded-md shadow-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl text-darkgreen">Your Map is Empty</p>
                                    <p className="text-lg text-litegreen mt-2">Adding your Address</p>

                                    <p className="text-xs text-white mt-2">1] On the left hand side of the screen navigate to the Account Page</p>
                                    <p className="text-xs text-white mt-1">2] Once in the Account Page enter your address into the field under settings</p>
                                    <p className="text-xs text-white mt-1">3] Make sure your address is properly formatted</p>
                                    <p className="text-xs text-white mt-1">4] Click on the [Save Preferences] button and that will save your address to your account</p>
                                    <p className="text-xs text-white mt-1">5] Once your address is saved you may navigate back to this page to see your map</p>

                                    <p className="text-lg text-litegreen mt-2">Network Issues</p>

                                    <p className="text-xs text-white mt-2">1] Make sure you are connected to the Internet</p>
                                </div>
                            </div>
                        ) : (
                            <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} absolute w-full h-full p-1.5 rounded-md shadow-gray-900 drop-shadow animate-fadeIn`}>
                                {mapCenter.lat !== 0 && mapCenter.lng !== 0 && (





                                    <APIProvider apiKey={'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E'} libraries={['drawing']}>
                                        <Map
                                            mapId={'e93a6ae3c62f080a'}
                                            defaultCenter={mapCenter}
                                            defaultZoom={mapZoom}
                                            zoomControl={true}
                                            mapTypeId={"satellite"}
                                            gestureHandling={'greedy'}
                                            disableDefaultUI={true}
                                            onClick={(event) => setMapClickCoords({
                                                lat: event.detail.latLng.lat,
                                                lng: event.detail.latLng.lng
                                            })}
                                        >

                                            {!loading && (
                                                <>
                                                    {selectedRoute && selectedRoute.path && selectedRoute.path.length > 0 && (
                                                        <Polyline
                                                            path={selectedRoute.path}
                                                            strokeColor="#FFA000"
                                                            strokeOpacity={1.0}
                                                            strokeWeight={2}
                                                        />
                                                    )}
                                                    <div className="text-sm absolute flex flex-col top-1 left-1">
                                                        <div className="flex flex-row mb-2 items-center">
                                                            <div
                                                                className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md w-fit items-center mr-2`}>
                                                                <div
                                                                    className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>Length:
                                                                </div>
                                                                <div className="text-xs">{length.toFixed(2)} miles</div>
                                                            </div>

                                                            <button
                                                                className="bg-bgcolorLiteGreen hover:bg-litegreen text-white text-xs shadow-gray-900 mr-auto ml-auto rounded-md h-fit p-0.5 w-fit"
                                                                onClick={handleRouteClear}>Clear Route
                                                            </button>

                                                        </div>

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md mb-2 w-fit items-center`}>
                                                            <div
                                                                className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>Date:
                                                            </div>
                                                            <div className="text-xs">{date}</div>
                                                        </div>

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md mb-2 w-fit items-center`}>
                                                            <div
                                                                className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>Start:
                                                            </div>
                                                            <div className="text-xs">{startTime}</div>
                                                        </div>

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md mb-2 w-fit items-center`}>
                                                            <div
                                                                className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>End:
                                                            </div>
                                                            <div className="text-xs">{endTime}</div>
                                                        </div>

                                                        <div
                                                            className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md mb-2 w-fit items-center`}>
                                                            <div
                                                                className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>Duration:
                                                            </div>
                                                            <div className="text-xs">{duration}</div>
                                                        </div>
                                                    </div>

                                                </>
                                            )}

                                            {/* Polygon Component Rendering */}
                                            {polygonPaths.map((path, index) => (
                                                <Polygon
                                                    key={index}
                                                    ref={(el) => (polygonRefs.current[index] = el ? el.getPath() : null)}
                                                    path={path}
                                                    options={{
                                                        strokeColor: "#00FF00",
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 2,
                                                        fillColor: "#00FF00",
                                                        fillOpacity: 0.35
                                                    }}
                                                    onClick={() => {
                                                        if (polygonRefs.current[index]) {
                                                            const area = calculatePolygonAreaInAcres(polygonRefs.current[index]);
                                                            setAreaInAcres(area);
                                                        }
                                                    }}
                                                />
                                            ))}
                                            <div className="text-sm absolute flex flex-col top-1 right-1">
                                                <div className={`${darkMode ? "bg-bgcolor text-white" : "bg-white text-black"} flex flex-row p-1 rounded-md mb-2`}>
                                                    <div className={`${darkMode ? "text-litegreen" : "text-darkgreen"} text-xs mr-1`}>Area:
                                                    </div>
                                                    <div className="text-xs">{areaInAcres.toFixed(2)} acres</div>
                                                </div>
                                            </div>


                                            <div className="text-sm absolute flex flex-col top-1 right-96 left-96">
                                                <div className="w-full">
                                                    <input
                                                        type="checkbox"
                                                        id="mapMode"
                                                        name="mapMode"
                                                        checked={mapMode}
                                                        onChange={(e) => setMapMode(e.target.checked)}
                                                        className="sr-only"
                                                    />
                                                    <label htmlFor="mapMode" className="cursor-pointer">
                                                        <div className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${mapMode ? 'bg-bgpage' : 'bg-bgcolorLight drop-shadow shadow-gray-900'}`}>
                                                            <div className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${mapMode ? 'bg-white translate-x-full' : 'bg-white'}`}>
                                                                {mapMode ? (
                                                                    <PencilIcon
                                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : (
                                                                    <MapPinIcon
                                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </div>

                                                            {mapMode && (
                                                                <button className="bg-litegreen p-1 rounded-md ml-20" onClick={saveClick}>Save</button>
                                                            )}
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>



                                            {customLocations.map((location, index) => (
                                                <>
                                                    <AdvancedMarker
                                                        key={index}
                                                        ref={markerRef}
                                                        position={{lat: location.latitude, lng: location.longitude}}
                                                        title={location.name}
                                                        onClick={() => handleMarkerClick(location)}>
                                                        <Pin{...getPinColors(location.type)}
                                                            scale={0.6}>
                                                            <div
                                                                className="text-white text-xxs mt-16 text-center bg-bgcolor rounded-md p-1">{location.name}</div>
                                                        </Pin>

                                                    </AdvancedMarker>

                                                    {infoWindowData ? (
                                                        <div className="absolute bottom-3 left-0 p-4"
                                                             style={{pointerEvents: 'none'}}>
                                                            <div
                                                                className={`${darkMode ? "bg-bgcolor" : "bg-white"} p-2 rounded-md w-56 relative`}
                                                                style={{pointerEvents: 'auto'}}>

                                                                <div className="flex flex-row">
                                                                    <button className="absolute top-2 left-2"
                                                                            onClick={toggleDeleteConfirmation}>
                                                                        <FontAwesomeIcon
                                                                            className="text-red-700 hover:text-red-500 text-sm"
                                                                            icon={faTrash}/>
                                                                    </button>

                                                                    <button className="absolute top-2 right-2"
                                                                            onClick={handleCloseWindow}>
                                                                        <FontAwesomeIcon
                                                                            className={`${darkMode ? "text-white" : "text-gray-400"} hover:text-litegreen text-sm`}
                                                                            icon={faEdit}/>
                                                                    </button>
                                                                </div>

                                                                <div className="text-sm text-litegreen text-center font-semibold mb-2">{infoWindowData.name}</div>

                                                                <div className={`${darkMode ? "text-white" : "text-black"} mb-2 text-xs`}>
                                                                    <span className="font-semibold text-litegreen">Type:</span> {infoWindowData.type}
                                                                </div>

                                                                <div className={`${darkMode ? "text-white" : "text-black"} mb-2 text-xs`}>
                                                                    <span className="font-semibold text-litegreen">Latitude:</span> {infoWindowData.latitude}
                                                                </div>

                                                                <div className={`${darkMode ? "text-white" : "text-black"} mb-2 text-xs`}>
                                                                    <span className="font-semibold text-litegreen">Longitude:</span> {infoWindowData.longitude}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="absolute bottom-3 left-0 p-4"
                                                             style={{pointerEvents: 'none'}}>
                                                            <div
                                                                className={`${darkMode ? "bg-bgcolor" : "bg-white"} p-4 rounded-md w-64 relative text-center text-white`}
                                                                style={{pointerEvents: 'auto'}}>
                                                                <p className="text-lg text-litegreen">No Marker
                                                                    Selected</p>

                                                                <p className={`${darkMode ? "text-white" : "text-black"} text-xs mt-1`}>Click
                                                                    on a marker to see details here.</p>
                                                            </div>
                                                        </div>
                                                    )}


                                                    {/* Deletion confirmation popup */}
                                                    {showDeleteConfirmation && (
                                                        <div
                                                            className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                                            <div className="bg-bgcolor p-4 rounded-md shadow-sm">
                                                                <p className="text-white">Are you sure you want to
                                                                    delete {infoWindowData.name}</p>
                                                                <div className="flex mt-2">
                                                                    <button
                                                                        className="w-full rounded-md bg-red-700 hover:bg-red-500 text-white p-1 mr-5"
                                                                        onClick={handleDelete}>Yes
                                                                    </button>
                                                                    <button
                                                                        className="w-full rounded-md bg-darkgreen hover:bg-litegreen text-white p-1"
                                                                        onClick={toggleDeleteConfirmation}>No
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}


                                                </>

                                            ))}

                                        </Map>
                                    </APIProvider>



                                    // <LoadScript
                                    //     id="script-loader"
                                    //     googleMapsApiKey={'AIzaSyDN45vy87lqI2YHWycqn-BKnsC1jMHPd2E'}
                                    //     libraries={["drawing"]}
                                    //     language="en"
                                    //     region="us"
                                    // >
                                    //     <GoogleMap
                                    //         mapContainerClassName="App-map"
                                    //         center={mapCenter}
                                    //         zoom={10}
                                    //         version="weekly"
                                    //     >
                                    //         <DrawingManager
                                    //             drawingMode={state.drawingMode}
                                    //             options={{
                                    //                 drawingControl: true,
                                    //                 drawingControlOptions: {
                                    //                     drawingModes: ["polygon"],
                                    //                 },
                                    //                 polygonOptions: {
                                    //                     fillColor: `#2196F3`,
                                    //                     strokeColor: `#2196F3`,
                                    //                     fillOpacity: 0.5,
                                    //                     strokeWeight: 2,
                                    //                     clickable: true,
                                    //                     editable: true,
                                    //                     draggable: true,
                                    //                     zIndex: 1,
                                    //                 },
                                    //             }}
                                    //             onPolygonComplete={(poly) => {
                                    //                 /*const polyArray = poly.getPath().getArray();
                                    //                 let paths = [];
                                    //                 polyArray.forEach(function(path) {
                                    //                   paths.push({ latitude: path.lat(), longitude: path.lng() });
                                    //                 });
                                    //                 console.log("onPolygonComplete", polyArray);*/
                                    //                 console.log("onPolygonComplete", poly);
                                    //                 // noDraw();
                                    //             }}
                                    //             /*onOverlayComplete={poly => {
                                    //               const polyArray = poly.getPath().getArray();
                                    //               let paths = [];
                                    //               polyArray.forEach(function(path) {
                                    //                 paths.push({ latitude: path.lat(), longitude: path.lng() });
                                    //               });
                                    //               console.log("onOverlayComplete", polyArray);
                                    //             }}*/
                                    //         />
                                    //     </GoogleMap>
                                    // </LoadScript>



                                )}


                                {mapMode ? (
                                    mapClickCoords.lat !== 0 && mapClickCoords.lng !== 0 && (
                                    // Content to display when mapMode is false
                                    // <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                    //     <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} w-96 p-4 rounded-md shadow-gray-900`}>
                                    //
                                    //          <button
                                    //             type="button"
                                    //             className={`${darkMode ? "text-white" : "text-black"} rounded-lg text-2xl`}
                                    //             onClick={handleClose}
                                    //         >
                                    //             <FontAwesomeIcon icon={faXmark} />
                                    //         </button>
                                    //
                                    //         <div className="text-sm text-litegreen font-bold text-center mb-2">
                                    //             Drawing PolyGon Mode
                                    //         </div>
                                    //         <p className="text-center text-sm">Here you can display information or UI relevant to when the map mode is off.</p>
                                    //     </div>
                                    // </div>

                                        <div className="">Hello</div>
                                    )
                                ) : (
                                    // Content to display when mapMode is true
                                    mapClickCoords.lat !== 0 && mapClickCoords.lng !== 0 && (
                                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                            <div>
                                                <div className={`${darkMode ? "bg-bgcolor" : "bg-white"} w-96 p-2 rounded-md shadow-gray-900`}>
                                                    <button type="button"
                                                        className={`${darkMode ? "text-white" : "text-black"} relative top-0 left-0 rounded-lg text-2xl`}
                                                        onClick={handleClose}>
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </button>
                                                    <div className="text-sm text-litegreen font-bold text-center mb-2">
                                                        Add a New Location to the Map
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="flex items-center justify-center mt-2 mb-2">
                                                            <label
                                                                className={`${darkMode ? "text-white" : "text-black"} block text-sm mr-5`}
                                                                htmlFor="serviceDate"
                                                            >
                                                                Name:
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Location Name"
                                                                className={`${darkMode ? "bg-bgcard shadow-inner" : "bg-white drop-shadow"} appearance-none rounded-md w-full py-1 px-3 text-white leading-tight shadow-gray-900`}
                                                                value={locationName}
                                                                onChange={(e) => setLocationName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="flex items-center w-full justify-start mt-2 mb-2">
                                                            <label
                                                                className={`${darkMode ? "text-white" : "text-black"} block text-sm mr-3`}
                                                                htmlFor="classification"
                                                            >
                                                                Classification:
                                                            </label>
                                                            <div className="w-full flex items-center justify-end mt-2 mb-2">
                                                                <select
                                                                    id="classification"
                                                                    name="classification"
                                                                    value={locationType}
                                                                    onChange={(e) => setLocationType(e.target.value)}
                                                                    className={`${darkMode ? "bg-bglite text-white shadow-inner" : "bg-white text-black drop-shadow"} appearance-none w-full rounded-md py-1 px-3 leading-tight shadow-gray-900`}
                                                                >
                                                                    <option value="">Select...</option>
                                                                    <option value="Green">Green</option>
                                                                    <option value="Fairway">Fairway</option>
                                                                    <option value="Tee">Tee</option>
                                                                    <option value="Structure">Structure</option>
                                                                    <option value="Irrigation">Irrigation</option>
                                                                    <option value="Bunker">Bunker</option>
                                                                </select>
                                                                <div className={`${darkMode ? "text-white" : "text-gray-400"} text-xl ml-2`}>
                                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex w-full">
                                                            <button
                                                                type="submit"
                                                                className="w-full rounded-lg bg-darkgreen text-white p-1 hover:bg-litegreen"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}



                            </div>
                        )}
                    </div>


                </div>

                <div className={` ${isCollapsed ? 'hidden' : `${darkMode ? "bg-bgcolor shadow-inner shadow-gray-900" : "bg-bgcolorGreen"} flex flex-col h-full rounded-md shadow-gray-900 mt-3 mb-3 mr-3 p-4 w-1/6`}`}>

                    <div className="flex flex-col overflow-y-auto h-full max-h-[calc(100vh-8.8rem)] scrollbar-none rounded-md">

                        <h2 className="text-lg text-white mb-4 text-center">Filter Locations</h2>

                        <div className="flex flex-col text-sm text-white">
                            <label className="mb-2">
                                <input type="checkbox" id="selectAll" checked={isSelectAllChecked} onChange={handleCheckboxChange}/>
                                <span className="ml-2">Select All</span>
                            </label>

                            <div className="ml-4 flex flex-col justify-center items-start">
                                <label className="mb-2">
                                    <input type="checkbox" id="Green" checked={isChecked.Green} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Greens</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Fairway" checked={isChecked.Fairway} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Fairways</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Tee" checked={isChecked.Tee} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Tees</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Structure" checked={isChecked.Structure} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Structures</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Irrigation" checked={isChecked.Irrigation} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Irrigation</span>
                                </label>

                                <label className="mb-2">
                                    <input type="checkbox" id="Bunker" checked={isChecked.Bunker} onChange={handleCheckboxChange}/>
                                    <span className="ml-2">Bunker</span>
                                </label>

                            </div>

                            <div className="mb-4 text-lg text-center text-white">Set Map Zoom:</div>

                            <div className="flex flex-col mb-4 items-center justify-center">
                                <div className="w-32">
                                    <input
                                        type="range"
                                        min="1"
                                        max="18"
                                        value={mapZoom}
                                        onChange={handleChangeSlider}
                                        className={`${darkMode ? "dark:bg-gray-700" : "bg-white"} w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer`}
                                    />
                                    <div className="flex justify-between text-xs text-white mt-1">
                                        <span>1</span>
                                        <span>18</span>
                                    </div>
                                </div>
                                <div className="text-md text-litegreen">
                                    Value: {mapZoom}
                                </div>
                            </div>

                            <div className="flex w-full mb-4">
                                <button type="submit" className="w-full rounded-lg bg-darkgreen text-white p-1 hover:bg-litegreen">
                                    ReCenter
                                </button>
                            </div>




                            <div className="flex items-center justify-center flex-col">
                                <p className="text-lg text-white mb-2">Spray Routes</p>

                                {loading ? (
                                    <p>Loading routes...</p>
                                ) : (
                                    <ul>
                                        {routes.map(route => (
                                            <li key={route.id}>
                                                <button className={`${darkMode ? "bg-bgcolor shadow-md text-white" : "bg-white drop-shadow text-black"} w-full h-fit shadow-gray-900 flex flex-col text-xs text-center rounded-md mb-2 p-2`} onClick={() => handleRouteClick(route)}>{route.id}
                                                    <div className="bg-darkgreen w-20 mr-auto ml-auto text-xs text-white mt-2 rounded-md p-0.5 hover:bg-litegreen">View Route</div>

                                                    <div className="">
                                                        <FontAwesomeIcon className={`${darkMode ? "text-white" : "text-gray-400"} absolute top-1 left-1 hover:text-litegreen text-sm`} icon={faEdit}/>
                                                    </div>

                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default MapView;
