import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { db } from '../../firebase/firebase'; // Import the db object from your firebase configuration file
import { Link } from 'react-router-dom';
import logo from '../../FullLogo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown, faCaretLeft,
    faCaretRight,
    faCaretUp,
} from '@fortawesome/free-solid-svg-icons';

import Header from "../header";
import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    BeakerIcon,
    ChartBarSquareIcon, ChartPieIcon,
    EyeIcon,
    HomeIcon, PresentationChartBarIcon, PresentationChartLineIcon,
    QuestionMarkCircleIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import {CalculatorIcon} from "@heroicons/react/24/outline";
import {TruckIcon} from "@heroicons/react/24/outline";
import {UserGroupIcon} from "@heroicons/react/24/outline";
import {MapIcon} from "@heroicons/react/24/outline";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {ChartBarIcon} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";
import Home from "../Home";




const Dashboard = () => {
    // Get the current pathname

    const { currentUser } = useAuth();
    const [userName, setUserName] = useState('');
    // const [userEmail, setUserEmail] = useState('');
    const [userTitle, setUserTitle] = useState('');
    const [userCompany, setUserCompany] = useState('');

    const [profileImage, setProfileImage] = useState('');

    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath

    const { darkMode } = useDarkMode();



    const [expanded, setExpanded] = useState(currentPath === '/ChemsDash' || currentPath === '/SpraysDash' || currentPath === '/FleetDash' || currentPath ==="/EmployeeDash");

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setUserName(userData.fname);
                        setUserTitle(userData.title);
                        setUserCompany(userData.company);
                        setProfileImage(userData.profileImage);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setUserName(userData.fname);
                            setUserTitle(userData.title);
                            setUserCompany(userData.company);
                            setProfileImage(userData.profileImage);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    return (

        <div className={`flex flex-col justify-between ${collapse ? 'w-14 m-3' : 'w-80 p-3'}`}>

            <Header/>

            <div className={`z-20 overflow-y-auto ${darkMode ? "bg-bgDark" : "bg-bgcolorGreen"} rounded-md shadow-inner shadow-gray-750 h-full max-h-[calc(100vh-5.5rem)] scrollbar-none ${collapse ? 'p-1' : 'p-3.5'}`}>

                <button className="text-xl absolute left-1 text-white font-bold mt-auto mb-auto hover:bg-opacity-50 bg-green-600 w-8 h-8 rounded-full"
                        onClick={toggleCollapse}>
                    {collapse ? <ArrowRightCircleIcon/> : <ArrowLeftCircleIcon/>}
                </button>


                <div className="flex flex-row">
                    <div className={`text-2xl w-full text-center text-white mb-1 ${collapse ? 'hidden' : ''}`}>Dashboard</div>
                </div>


                <div className="p-1 flex items-center rounded-md">

                    {/*<img*/}
                    {/*    src={logo}*/}
                    {/*    alt="Profile"*/}
                    {/*    className="object-cover w-full rounded-md"*/}
                    {/*    style={{minWidth: '100%', minHeight: '100%'}}*/}
                    {/*/>*/}


                    <div className={`rounded-md bg-bgcard flex items-center justify-center shadow-inner shadow-gray-850 ${collapse ? 'w-12 h-12 mb-2 mt-10' : 'w-12 h-12 mr-2'}`}>
                        {/*<FontAwesomeIcon icon={faUser} className="text-white text-xl"/>*/}
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="object-cover w-full h-full rounded-md"
                            style={{minWidth: '100%', minHeight: '100%'}}
                        />
                    </div>

                    <ul className="menu" style={{listStyleType: 'none', padding: 0}}>

                        {/*<li className={`text-white p-1.5 rounded-md ${collapse ? 'hidden' : ''}`}>*/}
                        {/*    <div className="text-sm text-white mb-2">{userName}</div>*/}
                        {/*    <div className={`text-xs/[8px] ${darkMode ? "text-litegreen" : "text-gray-400"} ml-2 mb-1`}>{userTitle}</div>*/}
                        {/*</li>*/}

                    </ul>
                </div>

                <div className={`${darkMode ? "bg-greyText" : "bg-gray-200"} h-0.5 w-full shadow-inner shadow-gray-900 ${collapse ? 'mb-2' : 'mb-5'}`}></div>


                <ul className="menu justify-center items-center" style={{listStyleType: 'none', padding: 0}}>

                    <div className={`relative text-xs text-center text-litebgpage ml-1.5 mr-1.5 mt-1 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Main</div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>

                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Home" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Home' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <HomeIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Home</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Map" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Map' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <MapIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Map</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Calendar" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Calendar' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <CalendarDaysIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Calendar</div>
                        </Link>
                    </li>

                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Vision" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Vision' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <EyeIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Greens Vision</div>
                        </Link>
                    </li>


                    {/*----------------------------------------------------------------------*/}

                    <div className={`relative text-xs text-center text-litebgpage ml-1.5 mr-1.5 mt-1 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Data</div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Vision" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Vision' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <PresentationChartBarIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Dashboard</div>
                        </Link>
                    </li>



                    {/*----------------------------------------------------------------------*/}

                    <div className={`relative text-xs text-center text-litebgpage ml-1.5 mr-1.5 mt-1 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Calc</div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Chems" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Chems' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <BeakerIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Chemical Analytics</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Sprays" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Sprays' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <CalculatorIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Spray Calculator</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Fleet" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Fleet' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <TruckIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Fleet Management</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Team" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Team' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <UserGroupIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Team Management</div>
                        </Link>
                    </li>









                    <div className={`relative text-xs text-center text-litebgpage ml-1.5 mr-1.5 mt-1 mb-3`}>
                        <div className={`absolute inset-0 flex items-center justify-center`}>
                            <div className={`${darkMode ? 'bg-bgDark' : 'bg-bgcolorGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider text-small`}>Tools</div>
                        </div>

                        <div className={`bg-bgcolorLiteGreen w-full rounded-full h-0.5`}></div>
                    </div>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/Account" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Account' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <UserIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Account</div>
                        </Link>
                    </li>


                    <li className={`cursor-pointer flex items-center hover:scale-105`}>
                        <Link to="/FAQ" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/FAQ' ? 'bg-white bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                            <div className={`w-5 h-5 rounded bg-bgcolorLiteGreen drop-shadow shadow-2xl shadow-black p-3.5 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                <QuestionMarkCircleIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                            </div>
                            <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>FAQ</div>
                        </Link>
                    </li>


                </ul>
            </div>


        </div>
    );
};

export default Dashboard;

